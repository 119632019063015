import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGrey, width = "200", height = "143", secondaryColor = MAIN_CONFIG.COLORS.fillRed, ariaLabel = "Image Placeholder", ariaLabelDescription = "Image Placeholder" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 67 48">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <defs>
                <clipPath id={`clip-iPhone_12_Pro_Max_1-${id}`}>
                    <rect width="67" height="48" />
                </clipPath>
            </defs>
            <g id={`iPhone_12_Pro_Max_1-${id}`} data-name="iPhone 12 Pro Max – 1" clipPath="url(#clip-iPhone_12_Pro_Max_1)">
                <path id={`Path_7780-${id}`} data-name="Path 7780" d="M71,17H4V65H71Zm-2.913,2.909V55.247l-14.3-14.655L44.011,49,27.931,36.433,6.913,57V19.909ZM6.913,62.091V61.058L28.142,40.273,44.1,52.753l9.518-8.167L68.087,59.415v2.676Z" transform="translate(-4 -17)" fill={color} />
                <path id={`Path_7781-${id}`} data-name="Path 7781" d="M74.084,36.509a5.754,5.754,0,1,0-5.754-5.754A5.754,5.754,0,0,0,74.084,36.509Zm0-9.207a3.453,3.453,0,1,1-3.453,3.453A3.453,3.453,0,0,1,74.084,27.3Z" transform="translate(-18.609 -19.572)" fill={color} />
            </g>
        </svg>
    );
};
