import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './AchInfo.scss';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import CTAButton from '../../CTAButton/CTAButton';
import FlashMessage from '../../FlashMessage/Message';
import Loader from '../../Loader/Loader';
import { toast } from 'react-toastify';
import FilterSelector from '../../FilterSelector/FilterSelector';
import { MAIN_CONFIG } from '../../../../config/main';

const AchInfo = ({ accountData, setActiveAllFilters }) => {
    const API = new KaleidoscopeAPI({});
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [showExportButton, setShowExportButton] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false);
    const [accessDeniedMessage, setAccessDeniedMessage] = useState('')
    const [accessDenied, setAccessDenied] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                await checkAchInfoEligibility();
                setDataLoaded(true);
                setLoading(false);
            } catch (error) {
                // error handling
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [])

    const checkAchInfoEligibility = async () => {
        const response = await API.achInfoExport({ token: accountData.token, filters: { filters, check_eligibility: true } });
        if (response?.success) {
            setLoading(false)
        } else {
            setAccessDenied(true)
            setAccessDeniedMessage(response.message)
        }
    }

    const achInfoExport = async (run_export) => {
        if (Object.keys(filters).length > 0) {
            const response = await API.achInfoExport({ token: accountData.token, filters: { filters, run_export: run_export } });
            if (response.success) {
                if (run_export) {
                    setLoading(true)
                    toast.success(<FlashMessage type="success" message={response.message} />)
                } else {
                    setShowExportButton(response)
                }
            } else {
                toast.error(<FlashMessage type="error" message={response.message} />)
                setShowExportButton({})
            }
            setLoading(false)
        } else {
            toast.error(<FlashMessage type="error" message='Please select at least one filter to apply' />)
        }
    }

    /**disbursements ach info filters */
    const filtersList = [
        {
            type: "status__c",
            label: "Status",
            options: MAIN_CONFIG.ACH_INFO_FILTERS.STATUS,
            isMultiple: true,
        }
        ,
        {
            type: "funds_managed_by__c",
            label: "Funds Managed By",
            options: MAIN_CONFIG.ACH_INFO_FILTERS.FUNDS_MANAGED_BY,
            isMultiple: true,
        },
        {
            type: "payment_type__c",
            label: "Payment Type",
            options: MAIN_CONFIG.ACH_INFO_FILTERS.PAYMENT_TYPE,
            isMultiple: true,
        },
        {
            type: "date_disbursed__c",
            label: "Date Disbursed",
            optionType: "date-range",
        }
    ];


    const addFilters = filtersList => {
        let newFilters = {
            ...filters,
        }

        for (let i = 0, total = filtersList.length; i < total; i++) {
            const filter = filtersList[i]

            if (!filter.value) {
                delete newFilters[filter.type]
            } else {
                newFilters[filter.type] = filter.value
            }
        }

        setFilters(newFilters)
        setActiveAllFilters(newFilters)
        setShowExportButton({})
    }

    const removeFilters = filterTypes => {
        const currentFilters = filters

        for (let i = 0, total = filterTypes.length; i < total; i++) {
            delete currentFilters[filterTypes[i]]
        }

        setFilters(currentFilters)
        setActiveAllFilters(currentFilters)
        setShowExportButton({})
    }

    return (
        <>
            <Loader loading={loading || !dataLoaded} />
            {dataLoaded &&
                <div className="admin-main">
                    <div className="user-activities-menu">
                        <div className="user-activities">
                            <div className="section group">
                                {!accessDenied ?
                                    <div className="col span_12_of_12">
                                        <div className="H5DesktopGreen">ACH Info</div>
                                        <hr class="H5HeadingDivider"></hr>
                                        <div className='donor-scholarships-page__top-copy'>
                                            <FilterSelector
                                                selectedFilters={null}
                                                notShowHead={false}
                                                filters={filtersList}
                                                addFilters={addFilters}
                                                removeFilters={removeFilters}
                                                isApplicantList={true}
                                                activeFilters={filters}
                                                accountData={accountData}
                                                isAchInfoFilters={true}
                                            />
                                        </div>
                                        <div className='footer-btn' style={{ marginTop: '40px' }}>
                                            <CTAButton id='search-user' type='primary' onClick={(e) => achInfoExport(false)}>
                                                Apply Filters
                                            </CTAButton>
                                        </div>
                                    </div>
                                    :
                                    <div class="access-denied">
                                        <h1 class="access-denied__title">{accessDeniedMessage}</h1>
                                    </div>
                                }
                            </div>
                        </div>
                        {showExportButton?.success &&
                            <div className='user-activities ach-info-details' >
                                <div className='BodyDefaultRegularBlack ach-info-details__message'>
                                    {`${showExportButton?.message}`}
                                </div>
                                <div className='ach-info-details__submit'>
                                    <CTAButton id='search-user' type='primary' onClick={(e) => achInfoExport(true)}>
                                        Run Export
                                    </CTAButton>
                                </div>
                            </div>
                        }
                    </div>
                </div>}
        </>
    )
}

AchInfo.propTypes = {
    accountData: PropTypes.object,
    setActiveAllFilters: PropTypes.func
};

export default AchInfo;
