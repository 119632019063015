import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AppPage from "../../Core/AppPage";
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import { MAIN_CONFIG } from '../../../config/main';

import { ToastContainer, toast } from 'react-toastify';
import { reduxPage } from '../../Core/store'


import AdminLogin from '../../Components/Admin/AdminLogin/AdminLogin';
import ClientUsers from '../../Components/Admin/ClientUsers/ClientUsers';
import RBMUsers from '../../Components/Admin/RBMUsers/RBMUsers';
import DuplicateRecords from '../../Components/Admin/DuplicateRecords/DuplicateRecords';
import UserActivites from '../../Components/Admin/UserActivites/UserActivites';
import RecommenderUpdate from '../../Components/Admin/RecommenderUpdate/RecommenderUpdate';
import UserEmailHistory from '../../Components/Admin/UserEmailHistory/UserEmailHistory';
import LoginHistory from '../../Components/Admin/LoginHistory/LoginHistory';
import PromoteUsers from '../../Components/Admin/PromoteUsers/PromoteUsers';
import UsersPasswordReset from '../../Components/Admin/UsersPasswordReset/UsersPasswordReset';
import Dashboard from '../../Components/Admin/Dashboard/Dashboard';
import ApplicationUpdate from '../../Components/Admin/ApplicationUpdate/ApplicationUpdate';
import FrontendToSalesforceSync from '../../Components/Admin/FrontendToSalesforceSync/FrontendToSalesforceSync';
import AdminSSoDetails from '../../Components/AdminSSoDetails/AdminSSoDetails';
import CopyleaksScan from '../../Components/Admin/CopyleaksScan/CopyleaksScan';
import AchInfo from '../../Components/Admin/AchInfo/AchInfo';
import PortfolioProgram from '../../Components/Admin/PortfolioProgram/PortfolioProgram';



const SuperAdmin = ({
    pageData,
    accountData,
    userContext,
    setActiveHeaderNav,
    activeAdminTab = false,
    setAdminActiveTab = () => { },
    setModalState = () => { },
    setActiveAllFilters
}) => {

    const API = new KaleidoscopeAPI({});
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    /**
     * Determines which content should be shown based on active tab.
     * @returns {JSX.Element}
     */



    useEffect(() => {

        if (activeAdminTab !== MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROMOTEUSER || MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERPASSWORDRESET) {
            setModalState({
                visible: false,
                className: '',
                content: []
            });
        }

    }, [activeAdminTab]);
    const renderMainPageContent = () => {
        switch (activeAdminTab) {

            // Case Start
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DASHBOARD:
                return (
                    <Dashboard
                        accountData={accountData}
                        setModalState={setModalState}
                    />
                )
            // Case End

            // Case Start
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ADMINLOGIN:
                return (
                    <AdminLogin
                        accountData={accountData}
                        setModalState={setModalState}
                    />
                )

            // Case End

            // Case Start
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROMOTEUSER:
                return (
                    <PromoteUsers
                        accountData={accountData}
                        setModalState={setModalState}
                        isActiveTab={activeAdminTab}
                    />
                )

            // Case End

            // Case Start
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATERECOMMENDER:
                return (
                    <RecommenderUpdate
                        accountData={accountData}
                        setModalState={setModalState}
                    />
                )

            // Case End

            // Case Start
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERPASSWORDRESET:
                return (
                    <UsersPasswordReset
                        accountData={accountData}
                        setModalState={setModalState}
                    />
                )

            // Case End

            // Case Start
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERACTIVITIES:
                return (
                    <UserActivites
                        accountData={accountData}
                        setModalState={setModalState}
                    />
                )

            // Case End

            // Case Start
            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.CLIENTUSERS:
            //     return (
            //         <ClientUsers
            //             accountData={accountData}
            //             setModalState={setModalState}
            //         />
            //     )

            // Case End

            // Case Start
            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.REVIEWBOARDUSERS:
            //     return (
            //         <RBMUsers
            //             accountData={accountData}
            //             setModalState={setModalState}
            //         />
            //     )

            // Case End

            // Case Start
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.LOGINHISTORY:
                return (
                    <LoginHistory
                        accountData={accountData}
                        setModalState={setModalState}
                    />
                )

            // Case End


            // Case Start
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EMAILHISTORY:
                return (
                    <UserEmailHistory
                        accountData={accountData}
                        setModalState={setModalState}
                    />
                )

            // Case End

            // Case Start
            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DUPLICATECONTACTS:
            //     return (
            //         <DuplicateRecords
            //             accountData={accountData}
            //             setModalState={setModalState}
            //         />
            //     )

            // Case End

            // Case Start
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATEAPPLICATION:
                return (
                    <ApplicationUpdate
                        accountData={accountData}
                        setModalState={setModalState}
                    />
                )

            // Case End

            // Case Start
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.FRONTENDTOSALESFORCESYNC:
                return (
                    <FrontendToSalesforceSync token={accountData.token} accountId={pageData.accountId} setModalState={setModalState} />
                )

            // Case End

            // Case Start 

            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SSODETAILS:
                return (
                    <AdminSSoDetails accountData={accountData} setModalState={setModalState} />)

            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.COPYLEAKSSCAN:
                return (
                    <CopyleaksScan accountData={accountData} />)

            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PORTFOLIOPROGRAM:
                return (
                    <PortfolioProgram accountData={accountData} />)
 
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ACHINFO:
                return (
                    <AchInfo accountData={accountData} setActiveAllFilters={setActiveAllFilters}/>)
            
            default:
                return null;
        
        }

    }

    return (
        <div className="admin-home background-color__light-grey">
            {renderMainPageContent()}
            <ToastContainer />
        </div>
    );
};

SuperAdmin.propTypes = {
    setActiveAllFilters: PropTypes.func
};

const SuperAdminPage = AppPage(SuperAdmin);

export default reduxPage(SuperAdminPage)