import React, { useState, useEffect } from 'react';
import SpaceInvader from '../../SVG/SpaceInvader'
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import Table from '../../Table/Table';
import CTAButton from '../../CTAButton/CTAButton';
// import './Donors.scss';
import Field from '../../Field/Field';
import { TableListingLoader } from '../../Loader/Loader';
import AirPlaneWithShadow from '../../SVG/AirPlaneWithShadow';
import { MAIN_CONFIG } from '../../../../config/main';
import LoginModal from '../../LoginModal/LoginModal';
import { checkMarketPlace } from '../../../Utility/HelperFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';
import Globe from '../../SVG/Globe';
import History from '../../SVG/History';
import './RecommenderUpdate.scss';
import Userinfo from '../../SVG/Userinfo';
import Person from '../../SVG/Person';
import Pencil from '../../SVG/Pencil';
import UserBadge from '../../UserBadge/UserBadge';
import Paginator from '../../Paginator/Paginator';
import RecommenderModal from '../../AddRecommender/RecommenderModal';
import FlashMessage from '../../FlashMessage/Message';
import { ToastContainer, toast } from 'react-toastify';

export default ({ accountData, setModalState }) => {

    const API = new KaleidoscopeAPI({});

    const [name, setApplicationName] = useState('');
    const [references, setReferences] = useState([]);
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [noRecommenders, setNoRecommenders] = useState(false);
    const [currentTotalRecords, setCurrentTotalRecords] = useState(0);
    const [currentSortDirection, setCurrentSortDirection] = useState('');
    const [currentSortColumn, setCurrentSortColumn] = useState('');

    const columnData = {
        table_headers: [
            { field_api_name__c: 'first_name__c', column_name__c: 'First Name', enable_sort__c: false, enable_search__c: true },
            { field_api_name__c: 'last_name__c', column_name__c: 'Last Name', enable_sort__c: false, enable_search__c: true },
            { field_api_name__c: 'email__c', column_name__c: 'Email', enable_sort__c: false, enable_search__c: true },
            { field_api_name__c: 'relationship_to_applicant__c', column_name__c: 'Relationship', enable_sort__c: false, enable_search__c: true },
            { field_api_name__c: 'heroku_role__c', column_name__c: 'Role', enable_sort__c: false, enable_search__c: true }
        ]
    };

    const searchUser = async (e) => {
        e.preventDefault();
        setLoading(true)
        await apiCall()
    }

    const apiCall = async () => {
        const result = await API.recommenderList({ token: accountData.token, applicationName: name });
        if (result.references) {
            if(result.references?.length > 0) {
                setReferences(result.references)
                setCurrentTotalRecords(result.pagination?.totalRecords)
            } else {
                setNoRecommenders(true)
                setReferences([])
            }
        } else {
            toast.error(<FlashMessage type="error" message={result.message} />)
        }
        setLoading(false)
    }
    /**
     * Fetch new scholarships based on sort request.
     * @param {string} columnName
     * @param {string} currentSortDirection
     */
    const handleSort = async (columnName, currentSortDirection) => {

        const newSortDirection = currentSortDirection.toUpperCase() === 'ASC' ? 'DESC' : 'ASC';
        setCurrentSortColumn(columnName);
        setCurrentSortDirection(newSortDirection);
    }



    const resetDetail = (e) => {
        e.preventDefault();
        setApplicationName('')
        setReferences('')
    }

    /**
     * Gets the CTA to show indivisual dusbursement details.
     * @param {object} disbursement
     * @returns {JSX.Element}
     */
    const getRowCta = (user) => {
        return (
            <CTAButton id='search-user' IconComponent={Pencil} onClick={(e) => showRecommenderModal(e, user)}>
                Edit
            </CTAButton>
        );
        return null;
    }


    /**
   * Adds the modal content and shows the modal.
   */
    const showRecommenderModal = (e, data) => {
        e.preventDefault()

        let object = {
            email: data.email__c,
            first_name: data.first_name__c,
            last_name: data.last_name__c,
            message: data.reference_message__c,
            relationship: data.relationship_to_applicant__c,
            id: data.id,
            question_set_number: data.question_set_number,
            heroku_id__c : data.heroku_id__c
        }

        setModalState({
            visible: true,
            id: 'recommender__create',
            content: <RecommenderModal
                handleClose={closeRecommenderModal}
                handleSubmit={handleRecommenderSubmit}
                questions={[]}
                onChange={() => { }}
                staticObj={object}
                applicationDependentConfigs={[]}
                herokuRole={data.heroku_role__c}
                MsgText={''}
                type={'edit'}
                recommenderList={[]}
                dependentConfigsForVariousSteps={[]}
            />
        });
    }

    /**
    * Closes the modal.
    */
    const closeRecommenderModal = () => {
        setModalState({ visible: false });
    }

    const handleRecommenderSubmit = async (state, questionData, type) => {
        setLoading(true)

        let recommenderData = {
            first_name__c: state.first_name,
            last_name__c: state.last_name,
            email__c: state.email,
            relationship_to_applicant__c: state.relationship,
            reference_message__c: state.message
        }
        const response = await API.recommenderUpdate({ token: accountData.token, recommenderHerokuId: state.heroku_id__c, recommenderData });
        if (response.success) {
            await apiCall()
            closeRecommenderModal()
            toast.success(<FlashMessage message={response.message} />)
        } else {
            toast.error(<FlashMessage type="error" message={response.error} />)
        }
        setLoading(false)
    }

    const renderNoRecommenderMessage = () => {
        return (
            <>
            {!references.length > 0 && <div className="promote-user-menu">
                <div className="promote-user">
                    <div className="H5DesktopGreen">Update Recommender Details:  <span className="counts">{name}</span></div>
                    <hr className="H5HeadingDivider" />
                    <div className='DesktopGreen no-recommender-record'>Recommender record's not found for this application</div>
                </div>
            </div>}
            </>
        )
    }

    return (
        <div className="admin-main">
            {/*  */}
            <div className="update-recommender-menu">
                <div className="update-recommender">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <div className="H5DesktopGreen">Find Recommender</div>
                            <hr className="H5HeadingDivider" />
                            <form onSubmit={(e) => searchUser(e)}>
                                <Field
                                    id='admin-login-email-id'
                                    label='Application ID'
                                    placeholder='Enter Application ID. Example: AP1234'
                                    type='text'
                                    toolTipMessage='Get the recommender details by using application ID.  Example: AP1234'
                                    handleChange={value => setApplicationName(value)}
                                    value={name}
                                    required
                                />
                                <div className="footer-btn">
                                    <CTAButton id='search' type='primary'>
                                        Search
                                    </CTAButton>
                                    <CTAButton id='reset' onClick={(e) => resetDetail(e)}>
                                        Reset
                                    </CTAButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {(loading) ? <div className="skeleton-loader__container"><TableListingLoader /></div> : references.length > 0 && (
                    <div className="promote-user-menu">
                        <div className="promote-user">

                            <div className="H5DesktopGreen">Update Recommender Details:  <span class="counts">{name}</span></div>
                            <hr className="H5HeadingDivider" />
                            <Table
                                id={'applicant-screen__clients-table'}
                                showSelect={false}
                                getRowCta={getRowCta}
                                showRowCta={true}
                                columnData={columnData}
                                tableData={references}
                                handleSort={handleSort}
                                currentSortColumn={currentSortColumn}
                                currentSortDirection={currentSortDirection}
                            />
                        </div>
                    </div>
                )}
                {noRecommenders && renderNoRecommenderMessage()}
            </div >
        </div >
    )
}