import React, { useState } from 'react';
import Paginator from '../../Paginator/Paginator';
import Table from '../../Table/Table';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import { TableListingLoader } from '../../Loader/Loader';
import "./AdminModal.scss"

const AdminModal = ({ token, data, hideModal = () => { }, heading = "" }) => {

    const [tableData, setTableData] = React.useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalRecords, setTotalRecords] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [currentSortDirection, setCurrentSortDirection] = useState('');
    const [currentSortColumn, setCurrentSortColumn] = useState('');
  

    const API = new KaleidoscopeAPI({});


    const columnDataForModal = {
        table_headers: [
            { field_api_name__c: 'file_line_number', column_name__c: 'Row No', enable_search__c: true, enable_sort__c: true },
            { field_api_name__c: 'user_email', column_name__c: 'User Email', enable_search__c: true, enable_sort__c: true },
            { field_api_name__c: 'reason', column_name__c: 'Reason', enable_search__c: true, enable_sort__c: true },
        ]
    }


    const handleSort = async (columnName, currentSortDirection) => {
        const newSortDirection = currentSortDirection.toUpperCase() === 'ASC' ? 'DESC' : 'ASC';
        tableData.sort((a, b) => {
          var nameA = a[columnName];
          var nameB = b[columnName];
          if (newSortDirection === "ASC") {
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          } else {
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
          }
          return 0;
        });
        setCurrentSortColumn(columnName);
        setCurrentSortDirection(newSortDirection);
      }
  


    const fetchApi = async (token, data) => {
        const response = await API.failedUserRecords({ token: token, delayedJobQueueId: data.id, currentPage: currentPage ? currentPage : 1 });
        if (response) {
            setIsLoading(false)
            setTableData(response && response.delayed_job_failed_records)
            setCurrentPage(response.pagination.currentPage)
            setTotalRecords(response.pagination.totalRecords)
        }
    }


    React.useEffect(() => {
        setIsLoading(true)
        fetchApi(token, data, currentPage)
    }, [currentPage])


    const handlePagination = page => {
        setCurrentPage(page)
        setIsLoading(true)
    }



    return <>
        <div id='task-modal-content'>
            <div className='modal-header-container'>
                <h1 className='H2DesktopGreen'>{heading}</h1>
                <div className="task-head_container">
                    <a className="event-head H6DesktopGrey" onClick={hideModal}>X</a>
                </div>
            </div>

            {isLoading ? <div className="skeleton-loader__container"><TableListingLoader /></div> : <div><Table
                id={'applicant-screen__clients-table'}
                showSelect={false}
                columnData={columnDataForModal}
                tableData={tableData && tableData}
                handleSort={handleSort}
                currentSortColumn={currentSortColumn}
                currentSortDirection={currentSortDirection}
            />

                <Paginator
                    currentPage={currentPage}
                    totalRecords={totalRecords}
                    perPage={10}
                    handlePageClick={handlePagination}
                /> </div>}


        </div>
    </>
};

export default AdminModal;