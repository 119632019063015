import React, { useState, useEffect } from 'react';
import SpaceInvader from '../../SVG/SpaceInvader'
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import Table from '../../Table/Table';
import Paginator from '../../Paginator/Paginator';
import { debounce } from 'throttle-debounce';
import Loader, { TableListingLoader } from '../../Loader/Loader';
import Score from '../../SVG/Score';
import { MAIN_CONFIG } from '../../../../config/main';
import Field from '../../Field/Field';
import CTAButton from '../../CTAButton/CTAButton';
import LoginModal from '../../LoginModal/LoginModal';
import { checkMarketPlace } from '../../../Utility/HelperFunctions';
import Globe from '../../SVG/Globe';
import FieldCheckbox from '../../SVG/FieldCheckbox';
import FlashMessage from '../../FlashMessage/Message';
import { ToastContainer, toast } from 'react-toastify';
import Download from '../../SVG/Download';
import AdminModal from "../AdminModal/AdminModal"


export default ({ accountData, setModalState , isActiveTab}) => {

    const { USER_ROLE_CONTEXT, USER_ROLE_LIST } = MAIN_CONFIG;
    const API = new KaleidoscopeAPI({});

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
    const [userLink, setUserLink] = useState(false);
    const [csvFile, setCsvFile] = useState('');
    const [csvFileValue, setCsvFileValue] = useState('');

    const [perPage, setPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [currentTableData, setCurrentTableData] = useState([]);
    const [isClickPromote, setIsClickPromote] = useState(false);

    const [currentSortDirection, setCurrentSortDirection] = useState('');
    const [currentSortColumn, setCurrentSortColumn] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [currentTabTotalRecords, setCurrentTabTotalRecords] = useState(0); // Tracks current tab

    const columnData = {
        table_headers: [
            { field_api_name__c: 'file_name', column_name__c: 'File Name', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'job_activity', column_name__c: 'Job Activity', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'job_status', column_name__c: 'Job Status', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'total_records', column_name__c: 'Total Records', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'total_failed_records', column_name__c: 'Total Failed Records', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'started_at', column_name__c: 'Started At', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'completed_at', column_name__c: 'Completed At', enable_sort__c: true, enable_search__c: true },
        ]
    };

    useEffect(() => {
        updateTableData();
    }, [currentPage, currentSortColumn, currentSortDirection]);

    /**
     * Returns the columns that should be passed when performing a search.
     * @returns {*}
     */
    const getCurrentSearchableColumns = () => {
        return columnData.table_headers.filter(item => item.enable_search__c).map(item => item.field_api_name__c);
    }

    /**
     * Fetch new scholarships based on sort request.
     * @param {string} columnName
     * @param {string} currentSortDirection
     */
    const handleSort = async (columnName, currentSortDirection) => {

        const newSortDirection = currentSortDirection.toUpperCase() === 'ASC' ? 'DESC' : 'ASC';
        setCurrentSortColumn(columnName);
        setCurrentSortDirection(newSortDirection);
    }

    /**
     * Executes a search but only after input has stopped for the specified delay.
     * @type {function}
     */
    const handleSearch = async (e) => {
        e.preventDefault()
        let dataObj = {
            user_email: userEmail,
            user_role: userRole.value,
            send_magic_link_email: userLink.toString()
        }
        if (!userEmail && !csvFile) {
            toast.error(<FlashMessage type="error" message={'Enter Email id OR Upload CSV'} />)
        } else {
            setIsLoading(true)
            if (csvFile) {
                submitForm(e)
            } else {
                setIsClickPromote(true)
                let result = await API.promoteUser({ token: accountData.token, data: dataObj })
                if (result.success) {
                    toast.success(<FlashMessage message={result.message} />)
                    handleReset()
                } else {
                    toast.error(<FlashMessage type="error" message={result.message} />)
                }
                setIsClickPromote(false)
                setIsLoading(false)
            }
        }
    }

    const handleReset = () => {
        setUserEmail('')
        setUserRole('')
        setUserLink('')
        setCsvFile('')
        setCsvFileValue('')
        // setIsLoading(true)
    }
    /**
     * Fetches the data for the specified page number.
     * @param {number} page
     */
    const handlePagination = page => {
        setCurrentPage(page)
        setIsLoading(true)
    }

    /**
     * Update the table data respecting sort, search, and pagination.
     * @returns {Promise<void>}
     */
    const updateTableData = async () => {
        const options = {
            page: currentPage,
            sort: currentSortColumn ? `${currentSortColumn} ${currentSortDirection}` : false
        }

        const updatedTableData = await API.jobActivity({
            token: accountData.token,
            options,
            jobName: 'Promote Users'
        });
        setIsLoading(false)
        // TODO: Determine why the property check is necessary to prevent error.
        const tableData = updatedTableData.delayed_job_queues ? updatedTableData.delayed_job_queues : [];
        const { pagination } = updatedTableData;
        setCurrentTabTotalRecords(pagination.totalRecords);
        // setCurrentPage(pagination.currentPage);
        setCurrentTableData(tableData);

    }

    /**
     * Gets the CTA to show indivisual dusbursement details.
     * @param {object} disbursement
     * @returns {JSX.Element}
     */
    const getRowCta = (user) => {
        return (
            <CTAButton type='secondary' small>
                <a href='#' onClick={(e) => showModal(e, user)}>View</a>
            </CTAButton>
        );
        return null;
    }

    /**
     * remove the modal content and hide the modal.
     */
    const hideModal = (e) => {
        setModalState({
            visible: false,
            className: '',
            content: []
        });
    }

    const showModal = async (e, data) => {
        e.preventDefault();
            setModalState({
                visible: true,
                className: 'submit-validation-modal-popup',
                content: <AdminModal data = {data} token = {accountData.token} hideModal= {hideModal} heading = {"Promote User Job Records"}/>
            });
        }

    const handleUpload = (e) => {
        if (e.target.files[0]) {
            let file = e.target.files[0]
            // if (file.type !== 'text/csv') {
            //     toast.error(<FlashMessage type="error" message={`${file.name} is not a csv file`} />)
            // } else {
                setCsvFile(e.target.files[0])
                setCsvFileValue(e.target.value)
            // }
        }
    }

    const submitForm = async (e) => {
        e.preventDefault()
        var form_data = new FormData();
        form_data.append(`csv_file`, csvFile)
        form_data.append(`user_role`, userRole.value)
        form_data.append(`send_magic_link_email`, userLink.toString())
        const uploadCSV = await API.bulkPromoteUser(accountData.token, form_data);
        if (uploadCSV.success) {
            toast.success(<FlashMessage type="success" message={uploadCSV.message} />)
            updateTableData()
            handleReset()
        } else {
            toast.error(<FlashMessage type="error" message={uploadCSV.message} />)
        }
    }


    return (
        <div className="admin-main">
        <Loader loading={isLoading}/>
            {/*  */}
            <div className="promote-user-menu">
                <div className="promote-user">

                    {/*  */}
                    <div className="section group">
                        <div className="col span_12_of_12">

                            <div className="H5DesktopGreen">Promote User</div>
                            <hr className="H5HeadingDivider" />
                            <div className="email-address-or-upload-file-container">
                                <div className="enter-email-address">
                                    <Field
                                        id='admin-login-email-id'
                                        label='Email ID'
                                        placeholder='Enter Email ID'
                                        type='email'
                                        toolTipMessage='Promote user by Email ID'
                                        value={userEmail}
                                        handleChange={(value) => setUserEmail(value)}
                                    />
                                </div>
                                <div className="or-container">
                                    <span className="or-divider">OR</span>
                                </div>
                                <div className="upload-csv">
                                    <Field
                                        id='upload-csv'
                                        label='Upload CSV'
                                        placeholder='Upload CSV'
                                        type='file'
                                        toolTipMessage='Max File Size 100mb. File Types: csv.'
                                        handleChange={(e) => handleUpload(e)}
                                        value={csvFileValue}
                                    />
                                </div>
                            </div>


                            <div className="email-address-or-upload-file-container">
                                <div className="enter-email-address">
                                    <Field
                                        id='user-role'
                                        label='User Role'
                                        placeholder='Select user role'
                                        type='select'
                                        toolTipMessage='Select user role for promote'
                                        options={USER_ROLE_LIST}
                                        value={userRole}
                                        handleChange={(value) => setUserRole(value)}
                                    />
                                </div>
                                <div className="or-container">
                                    {/* <span className="or-divider">OR</span> */}
                                </div>
                                <div className="upload-csv">
                                    <a
                                        href='https://mykldexportsprod.blob.core.windows.net/userreports/imports/download-sample-files/UserPromotSample.csv'
                                        target="_blank"
                                        className="historyB download-sample-link"
                                        rel="noopener noreferrer"
                                        download
                                    >
                                        <Download width={25} height={25} color={MAIN_CONFIG.COLORS.fillWhite} className="download-icon" /> Download Sample File
                                    </a>
                                </div>
                            </div>
                            <div className="send-magic-link-email">
                                <label className="option-checkbox">
                                    <span className="checkbox__input">
                                        <input
                                            className="table__checkbox"
                                            type="checkbox"
                                            onChange={(e) => setUserLink(e.target.checked)}
                                            checked={userLink}
                                        />
                                        <span className="checkbox__control">
                                            <FieldCheckbox />
                                        </span>
                                    </span>
                                    <span className="checkbox-label BodyDefaultBoldBlack">
                                        Send Magic Link Email
                                    </span>
                                </label>
                            </div>

                            <div className="footer-btn">
                                <CTAButton id='search-user' type='primary' disabled={isClickPromote} onClick={(e) => handleSearch(e)}>
                                    Promote
                                </CTAButton>
                                <CTAButton id='reset' onClick={handleReset}>
                                    Reset
                                </CTAButton>
                            </div>
                        </div>
                    </div>
                    {/*  */}

                </div>
            </div>
            {(isLoading) ? <div className="skeleton-loader__container"><TableListingLoader /></div> : (
                <Table
                    id={'applicant-screen__clients-table'}
                    showSelect={false}
                    getRowCta={getRowCta}
                    showRowCta={true}
                    columnData={columnData}
                    tableData={currentTableData}

                    handleSort={handleSort}
                    currentSortColumn={currentSortColumn}
                    currentSortDirection={currentSortDirection}

                />
            )}
            <Paginator
                currentPage={currentPage}
                totalRecords={currentTabTotalRecords}
                perPage={perPage}
                handlePageClick={handlePagination}
            />
            {/*  */}
        </div>
    )
}