import React, { useState } from 'react';
import PropTypes from 'prop-types';
import "./ViewFile.scss";
import { Stream } from "@cloudflare/stream-react";
import ZoomIn from '../SVG/ZoomIn';
import ZoomOut from '../SVG/ZoomOut';

export default function ViewFile({ handleClose, name, content, embedCode, type }) {
    const [zoom,setZoom] = useState(1)

    const getContent = () => {
        if (type === 'image') {
            return `<div style="transform: scale(${zoom}); transition: transform 0.2s ease-in-out; overflow:auto; transform-origin: 0% 0%;">${content}</div>`
        }
        return content
    }

    const renderContent = () => {
        if (content && !embedCode) {
            return (<div className="media-embedded-viewer" dangerouslySetInnerHTML={{ __html: getContent() }} />)
        }
        return (<div className='cloudflare-iframe_container'><Stream  controls src={embedCode} /></div>)
    }

    const handleZoomIn = () => {
        setZoom(zoom + 0.25)
    }
    const handleZoomOut = (e) => {
        if (zoom > 1) {
            setZoom(zoom - 0.25)
        }
    }

    const renderZoom = () => {
        if (type === "image") {
            return (
                <div className='media-file_inner-container'>
                    <button className='cursorPointer' onClick={handleZoomOut}>
                        <ZoomOut />
                    </button>
                    <button className='cursorPointer' onClick={handleZoomIn}>
                        <ZoomIn />
                    </button>
                </div>
            )
        }
    }

    return (
        <div className="media-file-result-page">
            <div className="media-file-result-page__header">
                <div>
                    <h1 className='H1DesktopGreen media-file-result-title'>File</h1>
                </div>
                <div className='media-file_zoom-container'>
                    {renderZoom()}
                    <a className="event-head H6DesktopGrey close-btn" onClick={handleClose}>X</a>
                </div>
            </div>
            <div className='media-file-result-sub-title H6DesktopGray'>{name}</div>
                {renderContent()}
        </div>
    )
}
ViewFile.propTypes = {
    handleClose: PropTypes.func,
    name: PropTypes.string,
    content: PropTypes.string,
    embedCode : PropTypes.string,
    type: PropTypes.string
};
ViewFile.defaultProps = {
    handleClose: () => { },
    name: '',
    content: '',
    embedCode: null,
    type: ''
};