import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, height = "200px", width = "200px" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>Download</title>
            <desc>Download</desc>
            <defs>
                <clipPath id="clip-upload_1">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="upload_1" data-name="upload – 1" clipPath="url(#clip-upload_1)">
                <path id="iconmonstr-upload-thin" d="M75,126H8.333v50H191.667V126H125v-8.333h75v66.667H0V117.667H75Zm91.667,16.667A8.333,8.333,0,1,1,158.333,151,8.336,8.336,0,0,1,166.667,142.667Zm-62.5,0H95.833V18.642L56.375,66.408,50,61.042,100.175,1,150,60.958l-6.367,5.375-39.467-47.7Z" transform="translate(200 192.334) rotate(180)" fill={color} fillRule="evenodd" />
            </g>
        </svg>
    );
}