import React, { useEffect } from 'react';
import dynamic from 'next/dynamic'
import AppPage from '../../../Core/AppPage';
import './reviewer-home-page.scss';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';

const CustomError = dynamic(
    () => import('../../Custom404/CustomError'),
    { loading: () => <p>...</p> }
)

const TabbedTablePage = dynamic(
    () => import('../../../Components/TabbedTablePage/TabbedTablePage'),
    { loading: () => <p>...</p> }
)

const CTAButton = dynamic(
    () => import('../../../Components/CTAButton/CTAButton'),
    { loading: () => <p>...</p> }
)

import { MAIN_CONFIG } from '../../../../config/main';
import { useRouter } from 'next/router';
import { alretMsg } from '../../../Utility/ApplicationFormUtility';

const Loader = dynamic(
    () => import('../../../Components/Loader/Loader'),
    { loading: () => <p>...</p> }
)

const { URLS } = MAIN_CONFIG;

export const ReviewerHome = ({
    pageData,
    accountData,
    userContext,
    setActiveHeaderNav,
    setShowTableActionBar,
    setTableActionBarNumSelected,
    setTableActionBarNumTotal,
    setModalState
}) => {
    setActiveHeaderNav('dashboard');
    const showUnsubscribeConfirmationPopup = localStorage.getItem('show_unsubscribe_confirmation') || false
    
    useEffect(() => {
        if (window.location.pathname === "/reviewer") {
            localStorage.setItem('elementsRBM', false)
        }
        if (showUnsubscribeConfirmationPopup === 'true') {
            alretMsg("", setIsOpenSweetAlert, () => { }, false, '', () => {
                localStorage.setItem('show_unsubscribe_confirmation', false);
            },
                'Email Unsubscribe Confirmation',
                true,
                false
            );
            const timeoutId = setTimeout(() => {
                setIsOpenSweetAlert(false);
                localStorage.setItem('show_unsubscribe_confirmation', false);
            }, 30000);
            return () => clearTimeout(timeoutId);
        }
    }, []);

    const [loading, setLoading] = React.useState(false);
    const [isOpenSweetAlert, setIsOpenSweetAlert] = React.useState(false)
    const [itemCountOnPage, setItemCountOnPage] = React.useState({ label: '10', value: 10 })
    const router = useRouter();

    const { scholarships, persona_branding, pagination, scholarship_count_based_on_status } = pageData;
    const { currentAccount: { primary_color__c } } = accountData;
    const API = new KaleidoscopeAPI({});
    const primaryColor = primary_color__c;

    if (!persona_branding) {
        return <CustomError setModalState={setModalState} hideHeader={true} message="You have no applications to review at this time." BrokenPencilShow={false} />
    }
    // Gather Scholarship table data
    const allTabData = persona_branding.sub_tabs.Scholarships || persona_branding.sub_tabs.Programs;
    const rawTableData = scholarships;
    const rawTableDataProperty = 'scholarships';
    const tableDataStatusCounts = scholarship_count_based_on_status;
    const tableDataFetch = async (options) => {
        const { token, currentAccount } = accountData;
        return await API.fetchScholarships({ token, accountId: currentAccount.sfid, userContext, options })
    }
    const tableDataPostFetch = (tableData) => {
        // TODO: Determine why the property check is necessary to prevent error.
        return tableData[rawTableDataProperty] ? tableData[rawTableDataProperty] : [];
    }

    const handleCTAClick = (e, URLS) => {
        e.preventDefault();
        setLoading(true)
        router.push(URLS, undefined, { shallow: true })
    }

    /**
     * Gets the CTA to show for a specific scholarship.
     * @param {object} scholarship
     * @returns {JSX.Element}
     */
    const getRowCta = (scholarship) => {
        // TODO: Update cta text based on scholarship status

        return (
            <CTAButton type='secondary' small onClick={(e) => handleCTAClick(e, URLS.REVIEWER.SINGLE_SCHOLARSHIP(scholarship.sfid))}>
                View
            </CTAButton>
        );
    }
    const clickTableDataView = (scholarship) => {
        setLoading(true)
        router.push(URLS.REVIEWER.SINGLE_SCHOLARSHIP(scholarship.sfid), undefined, { shallow: true })
    }

    const convertedPrimaryColor = (primaryColor === "#FFFFFF" || primaryColor === "#ffffff" ? '#F7F7F3' : primaryColor)

    return (
        <>
            <Loader loading={loading} />
            <TabbedTablePage
                rawTableData={rawTableData}
                tableDataFetch={tableDataFetch}
                tableDataPostFetch={tableDataPostFetch}
                allTabData={allTabData}
                clickTableDataView={clickTableDataView}
                pagination={pagination}
                tableDataStatusCounts={tableDataStatusCounts}
                accountData={accountData}
                setShowTableActionBar={setShowTableActionBar}
                setTableActionBarNumSelected={setTableActionBarNumSelected}
                setTableActionBarNumTotal={setTableActionBarNumTotal}
                userContext={userContext}
                headerBackgroundColor={convertedPrimaryColor}
                id='reviewer-home-page'
                getRowCta={getRowCta}
                showRowCta={true}
                showSelect={false}
                setItemCountOnPage={setItemCountOnPage}
                itemCountOnPage={itemCountOnPage}
                showRecordPerPage={pagination.totalRecords > 10}
                totalRecordsCount={pagination.totalRecords}
                page = {'reviewerHomePage'}
            />
            {isOpenSweetAlert}
        </>
    );

}

const ReviewerHomePage = AppPage(ReviewerHome);

export default ReviewerHomePage;
