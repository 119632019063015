import React, { useState, useEffect } from 'react';
import './FrontendToSalesforceSync.scss';
import '../UserActivites/UserActivites.scss';

import KaleidoscopeAPI from "../../../Core/KaleidoscopeAPI"
import Table from '../../Table/Table';
import { TableListingLoader } from '../../Loader/Loader';
import Refresh from '../../../Components/SVG/Refresh';
import CTAButton from '../../CTAButton/CTAButton';
import { MAIN_CONFIG, FRONTENDTOSALESFORCESYNC_OPERATOR, FRONTEND_TO_SALESFORCE_SYNC_OBJECT } from '../../../../config/main';
import Globe from '../../SVG/Globe';
import Paginator from '../../Paginator/Paginator';
import HerokuErrorDetailsModal from '../../HerokuErrorDetailsModal/HerokuErrorDetailsModal';
import { toast } from 'react-toastify';
import FlashMessage from "../../FlashMessage/Message"
import Loader from '../../Loader/Loader';
import ArrowDown from '../../SVG/ArrowDown';
import AdminRecordFilterModal from '../../AdminRecordFilterModal/AdminRecordFilterModal';
import RecordPerPage from '../../RecordPerPage/RecordPerPage';
import { handleRediretInNewTab } from '../../../Utility/HelperFunctions';

export default ({ token, accountId, setModalState }) => {

    const [dataLoaded, setDataLoaded] = useState(false);
    const API = new KaleidoscopeAPI({});
    useEffect(() => {
               const fetchData = async () => {
            try {
                await apiCallFEtoSalesforceSync();
                await apiCallRowsFrontend();
                await ApiCallHerokuConnectError(localFiltersData, 1, localFiltersData ? true : false);
                await apiCallFrontendApplicationSyncToElements();
                await apiCallFrontendContactSyncToElements();

                setDataLoaded(true);
                setLoading(false);
            } catch (error) {
                // error handling
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [])

    let localFiltersData = JSON.parse(localStorage.getItem("adminTriggerlogFilters"));

    const [dataFE, setDataFE] = useState([])
    const [rowsFE, setRowsFE] = useState([])
    const [herokuError, setHerokuError] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [perPage, setPerPage] = useState({ herokuConnectErrors: {label: '10', value: 10}, frontendToSalesforceSync: {label: '10', value: 10}, frontendApplicationSyncToElements: {label: '10', value: 10}, frontendContactToElements: {label: '10', value: 10}, rowsInFrontend: {label: '10', value: 10} });
    const [currentPage, setCurrentPage] = useState({ herokuConnectErrors: 1, frontendToSalesforceSync: 1, frontendApplicationSyncToElements: 1, frontendContactToElements: 1, rowsInFrontend: 1 });
    const [currentTotalRecords, setCurrentTotalRecords] = useState({ herokuConnectErrors: 0, frontendToSalesforceSync: 0, frontendApplicationSyncToElements: 0, frontendContactToElements: 0, rowsInFrontend: 0 });
    const [loading, setLoading] = React.useState(false);
    const [modifidAppData, setModifidAppData] = useState([])
    const [modifidContactData, setModifidContactData] = useState([])
    const [tableNames, setTableNames] = useState([])

    const apiCallFEtoSalesforceSync = async (_e, activePage ,records) => {
        let page = activePage || currentPage?.frontendToSalesforceSync || 1
        let recordsPerPage = records || 10
        let response = await API.frontendToSalesforceSync({ token, accountId, page, recordsPerPage })
        setDataFE(response && response.trigger_logs && response.trigger_logs)
        setCurrentTotalRecords(prevState => ({
            ...prevState,
            frontendToSalesforceSync: response?.pagination?.totalRecords || 0,
        }));
        setIsLoading(false)

    }
    const handlePagination = async (page,objKey) => {
        setIsLoading(true)
        setCurrentPage({...currentPage,[objKey]:page})
        switch (objKey) {
            case FRONTEND_TO_SALESFORCE_SYNC_OBJECT.HEROKU_CONNECT_ERRORS:
                await ApiCallHerokuConnectError(localFiltersData, page , localFiltersData ? true : false)
                break
            case FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_TO_SALESFORCE_SYNC:
                await apiCallFEtoSalesforceSync({}, page)
                break
            case FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_APPLICATION_SYNC_TO_ELEMENTS:
                await apiCallFrontendApplicationSyncToElements({}, page)
                break
            case FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_CONTACT_TO_ELEMENTS:
                await apiCallFrontendContactSyncToElements({}, page)
                break
            case FRONTEND_TO_SALESFORCE_SYNC_OBJECT.ROWS_IN_FRONTEND:
                await apiCallRowsFrontend({}, page)
                break
            default:
               null;
        }
        window.scrollTo(0, 0)
    }

    const apiCallRowsFrontend = async (_e, activePage, records) => {
        let recordsPerPage = records || 10;
        let page = activePage || currentPage?.rowsInFrontend || 1;
        let response = await API.rowsInFrontend({ token, accountId, page, recordsPerPage });
            if (response && response.database_rows) {
                setRowsFE(response.database_rows);
                setCurrentTotalRecords(prevState => ({
                    ...prevState,
                    rowsInFrontend: response.database_rows.length || 0,
                }));
            } else {
                // Handle the case where the response is not as expected
                console.log("Unexpected API response format.");
            }
            setIsLoading(false);
    };

    const ApiCallHerokuConnectError = async (filters = {}, page, addFilters = false) => {
        setIsLoading(true)
        let obj = {}
        if (addFilters) {
            const { days, sf_message, table_name } = filters;

            if (days > 0) {
                obj.days = days
            }
            if (Object.values(sf_message)[0].value?.length > 0 && Object.values(sf_message)[1].length > 0) {
                let newObj = {}
                const {operator , message} = sf_message
                newObj.operator = operator.value
                newObj.message = message

                obj.sf_message = newObj
            }
            if (table_name.length > 0) {
                obj.table_name = table_name
            }
        }
        let response = await API.herokuConnectErrors({ token, page, obj, addFilters })
        if (response) {
            setHerokuError(response.errored_rows)
            setCurrentTotalRecords(prevState => ({
                ...prevState,
                herokuConnectErrors: response?.pagination?.totalRecords || 0,
              }));
            setTableNames(response?.table_names)
            setIsLoading(false)
            setModalState({
                visible: false,
                id: 'recommender__create',
                class: 'heroku-error-modal',
                content: <AdminRecordFilterModal handleClose={handleClose} optionData={FRONTENDTOSALESFORCESYNC_OPERATOR} ApiCallHerokuConnectError={ApiCallHerokuConnectError} tableNames={tableNames} setCurrentPage={setCurrentPage} currentPage={currentPage} />
            })
            if(response?.errored_rows?.length === 0){
                toast.error(<FlashMessage type="error" message={"No Record Found."} />)
            }
        } else {
            toast.error(<FlashMessage type="error" message={result.message} />)
        }
    }


    const apiCallHerokuErrorSingle = async (id) => {
        setLoading(true)
        let response = await API.herokuConnectSingleError({ token, id })
        if (response?.db_row) {
            setModalState({
                visible: true,
                id: 'recommender__create',
                class: 'heroku-error-modal',
                content: <HerokuErrorDetailsModal
                    handleClose={handleClose}
                    id={id}
                    token={token}
                    data={response?.db_row}
                    setLoading={setLoading}

                />
            })
        }
    }

    const calculateTotalRows = (data) => {
        return data?.length > 0 ? data?.map(data => data?.datarows).reduce((acc, cur) => acc + cur) : 0
    }

    const calculateTotalModifiedApplicationRows = (data) => {
        return data?.length > 0 ? data?.map(data => data?.total).reduce((acc, cur) => acc + cur) : 0
    }

    const calculateTotalModifiedContactRows = (data) => {
        return data?.length > 0 ? data?.map(data => data?.total).reduce((acc, cur) => acc + cur) : 0
    }

    const apiCallFrontendApplicationSyncToElements = async (_e, activePage, records) => {
        let recordsPerPage = records || 10
        let page = activePage || currentPage?.frontendApplicationSyncToElements || 1
        let response = await API.frontendApplicationSyncToElements({ token, accountId, page, recordsPerPage })
        if (response) {
            setIsLoading(false)
            setModifidAppData(response && response.modified_application_rows && response.modified_application_rows)
            setCurrentTotalRecords(prevState => ({
                ...prevState,
                frontendApplicationSyncToElements: response?.pagination?.totalRecords || 0,
              }));
        }
        setIsLoading(false)

    }

    const apiCallFrontendContactSyncToElements = async (_e, activePage, records) => {
        let page = activePage || currentPage?.frontendContactToElements || 1
        let recordsPerPage = records || 10
        let response = await API.frontendContactSyncToElements({ token, accountId, page, recordsPerPage })

        if (response) {
            setIsLoading(false)
            setModifidContactData(response && response.modified_contact_rows && response.modified_contact_rows)
            setCurrentTotalRecords(prevState => ({
                ...prevState,
                frontendContactToElements: response?.pagination?.totalRecords || 0,
              }));
        }
        setIsLoading(false)

    }


    const columnDataFESync = {
        table_headers: [
            { field_api_name__c: 'table_name', column_name__c: 'Table Name' },
            { field_api_name__c: 'total', column_name__c: 'Total' },
            { field_api_name__c: 'last_processed_at', column_name__c: 'Last Processed At' },
        ]
    };

    const columnDataDataRows = {
        table_headers: [
            { field_api_name__c: 'tablename', column_name__c: 'Table Name' },
            { field_api_name__c: 'datarows', column_name__c: `Data Rows (Total - ${calculateTotalRows(rowsFE)})` },
        ]
    };


    const columnDataModifiedApplication = {
        table_headers: [
            { field_api_name__c: 'account_name', column_name__c: 'Account Name' },
            { field_api_name__c: 'scholarship_name', column_name__c: 'Scholarship Name' },
            { field_api_name__c: 'total', column_name__c: `Data Rows (Total - ${calculateTotalModifiedApplicationRows(modifidAppData)})` },
        ]
    };

    const columnDataModifiedContect = {
        table_headers: [
            { field_api_name__c: 'firstname', column_name__c: 'First Name' },
            { field_api_name__c: 'lastname', column_name__c: 'Last Name' },
            { field_api_name__c: 'email', column_name__c: 'Email' },
            { field_api_name__c: 'total', column_name__c: `Data Rows (Total - ${calculateTotalModifiedContactRows(modifidContactData)})` },
        ]
    };


    const handleClick = (sfid) => {
        if (sfid) {
            // const url = `https://kaleidoscope.my.salesforce.com/${sfid}`,
            const url = `${process.env.NEXT_PUBLIC_SALESFORCE_URL}/${sfid}`
            // window.open(url, '_blank', 'noopener,noreferrer')
            handleRediretInNewTab(url)
        }
    }

    const handleClose = () => {
        setModalState({
            visible: false
        });
    }

    const handleHerokuRetrySync = async (id) => {
        setIsLoading(true)
        let response = await API.RetrySyncHerokuConnect({ token, id })

        if (response) {
            if (response.success) {
                ApiCallHerokuConnectError(localFiltersData, currentPage?.herokuConnectErrors , localFiltersData ? true : false)
                toast.success(<FlashMessage message={response.message} />)
            } else {
                ApiCallHerokuConnectError(localFiltersData, currentPage?.herokuConnectErrors , localFiltersData ? true : false)
                toast.error(<FlashMessage type="error" message={response.message} />)
            }
        }
    }


    const [showHerokuSync, setShowHerokuSync] = useState(false);
    const [pandingSycnSFToFE, setPandingSycnSFToFE] = useState(false);
    const [frontendAppToElements, setFrontendAppToElements] = useState(false);
    const [frontendContactToElements, setFrontendContactToElements] = useState(false);
    const [rowInFE, setRowInFE] = useState(false);

    const handleFilterClick = () => {
        setModalState({
            visible: true,
            id: 'recommender__create',
            class: 'heroku-error-modal',
            content: <AdminRecordFilterModal handleClose={handleClose} optionData={FRONTENDTOSALESFORCESYNC_OPERATOR} ApiCallHerokuConnectError={ApiCallHerokuConnectError} tableNames={tableNames} setCurrentPage = {setCurrentPage} currentPage={currentPage} />
        })
    }

    const updateCurrentPage = (page, objKey) => {
        setCurrentPage({ ...currentPage, [objKey]: page })
    }

    const updatePerPage = async (page, objKey) => {
        setIsLoading(true)
        setPerPage({ ...perPage, [objKey]: page })
        if (objKey === FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_APPLICATION_SYNC_TO_ELEMENTS) {
            await apiCallFrontendApplicationSyncToElements({}, 1, page?.value)
        }
        else {
            await apiCallFrontendContactSyncToElements({}, 1, page?.value)
        }
    }

    return (
        <div className="admin-main">
            <Loader loading={loading || !dataLoaded} />
            {dataLoaded && (<>
            <div className="user-activities-menu">
                <div className="email-history-menu">
                    <div className="email-history">
                        <div className="section group">
                            <div className="col span_12_of_12">
                                <div className="H5DesktopGreen">Heroku Connect Sync Failures ({currentTotalRecords?.herokuConnectErrors})
                                    <div className="refresh_btn_heroku">
                                        <CTAButton id='filter-btn' type='primary' onClick={handleFilterClick} class="filter-btn">
                                            Filter Records
                                        </CTAButton>
                                        <CTAButton className='toggle_section' id='search' type='primary' onClick={() => setShowHerokuSync(!showHerokuSync)}>
                                            <ArrowDown color={MAIN_CONFIG.COLORS.fillWhite} width={20} height={20} />
                                        </CTAButton>
                                    </div>
                                </div>
                                {showHerokuSync && (
                                    <section>
                                        <>
                                            <hr className="H5HeadingDivider" />
                                            <div className="timeline timeline-heroku-errors">
                                                {herokuError.map(error => {

                                                    return (

                                                        <>
                                                                <div className="timeline__event view-email-details active-box">
                                                                    <div className="view-email-details__output">
                                                                        <div className="p status-due font-weight-black text-secondary text-md">

                                                                            <div className="key-and-value-container">
                                                                                <div className="key-and-value-container__key">
                                                                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Table Name:
                                                                                </div>
                                                                                <div className="key-and-value-container__value">
                                                                                    <pre className="text-primary">{error.table_name}</pre>
                                                                                </div>
                                                                            </div>

                                                                            <div className="key-and-value-container">
                                                                                <div className="key-and-value-container__key">
                                                                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Record Id:
                                                                                </div>
                                                                                <div className="key-and-value-container__value">
                                                                                    <pre className="text-primary">{error.record_id}</pre>
                                                                                </div>
                                                                            </div>

                                                                            <div className="key-and-value-container">
                                                                                <div className="key-and-value-container__key">
                                                                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> SFID:
                                                                                </div>
                                                                                <div className="key-and-value-container__value record_sfid">
                                                                                    <pre className="text-primary" onClick={() => { handleClick(error.sfid) }}>{error.sfid}</pre>
                                                                                </div>
                                                                            </div>

                                                                            <div className="key-and-value-container">
                                                                                <div className="key-and-value-container__key">
                                                                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Action:
                                                                                </div>
                                                                                <div className="key-and-value-container__value">
                                                                                    {error.action}
                                                                                </div>
                                                                            </div>

                                                                            <div className="key-and-value-container">
                                                                                <div className="key-and-value-container__key sf_message">
                                                                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> SF Message:
                                                                                </div>
                                                                                <div className="key-and-value-container__value sf_message">
                                                                                    <pre className="text-primary">{error.sf_message}</pre>
                                                                                </div>
                                                                            </div>

                                                                            <div className="key-and-value-container">
                                                                                <div className="key-and-value-container__key">
                                                                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Old Values:
                                                                                </div>
                                                                                <div className="key-and-value-container__value">
                                                                                    {error.old_values && <pre className="text-primary location-container">{error.old_values}</pre>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="key-and-value-container">
                                                                                <div className="key-and-value-container__key">
                                                                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> New Values:
                                                                                </div>
                                                                                <div className="key-and-value-container__value">
                                                                                    <pre className="text-primary location-container">{error.new_values}</pre>
                                                                                </div>
                                                                            </div>

                                                                            <div className="key-and-value-container">
                                                                                <div className="key-and-value-container__key">
                                                                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Created At:
                                                                                </div>
                                                                                <div className="key-and-value-container__value">
                                                                                    <pre className="text-primary">{error.created_at}</pre>
                                                                                </div>
                                                                            </div>

                                                                            <div className="key-and-value-container">
                                                                                <div className="key-and-value-container__key">
                                                                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Updated At:
                                                                                </div>
                                                                                <div className="key-and-value-container__value">
                                                                                    <pre className="text-primary">{error.updated_at}</pre>
                                                                                </div>
                                                                            </div>

                                                                            <div className="key-and-value-container">
                                                                                <div className="key-and-value-container__key">
                                                                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Processed At:
                                                                                </div>
                                                                                <div className="key-and-value-container__value">
                                                                                    <pre className="text-primary">{error.processed_at}</pre>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="heroku-buttons">

                                                                            <CTAButton
                                                                                className="CTA color__white"
                                                                                // onClick={(e) => handleClickApplyNow()}
                                                                                type="tertiary"
                                                                                style={{ backgroundColor: "blue" }}
                                                                                onClick={() => handleHerokuRetrySync(error.id)}
                                                                            >
                                                                                Retry Sync
                                                                            </CTAButton>

                                                                            <CTAButton
                                                                                className="CTA color__white"
                                                                                // onClick={(e) => handleClickApplyNow()}
                                                                                type="tertiary"
                                                                                style={{ backgroundColor: "blue" }}
                                                                                onClick={() => apiCallHerokuErrorSingle(error.id)}
                                                                            >
                                                                                View Record
                                                                            </CTAButton>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        </>
                                                    )

                                                })
                                                }
                                            </div>
                                            <Paginator
                                                currentPage={currentPage?.herokuConnectErrors}
                                                totalRecords={currentTotalRecords?.herokuConnectErrors}
                                                perPage={perPage?.herokuConnectErrors?.value}
                                                handlePageClick={(page)=>handlePagination(page, FRONTEND_TO_SALESFORCE_SYNC_OBJECT.HEROKU_CONNECT_ERRORS)}
                                            />
                                        </>
                                    </section>)}
                            </div>
                        </div>
                    </div>

                </div>


            </div >




            <div className="frontend-to-salesforce-sync-menu">
                <div className="frontend-to-salesforce-sync">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <div className="header-container">
                                <div className="H5DesktopGreen">
                                    Pending sync from Frontend to Salesforce ({currentTotalRecords.frontendToSalesforceSync})
                                </div>
                                <div className='refresh_btn'>
                                    <CTAButton id='search' type='primary' onClick={apiCallFEtoSalesforceSync}>
                                        <Refresh color={MAIN_CONFIG.COLORS.fillWhite} width={20} height={20} /> Refresh
                                    </CTAButton>
                                    <CTAButton className='toggle_section' id='search' type='primary' onClick={() => setPandingSycnSFToFE(!pandingSycnSFToFE)}>
                                        <ArrowDown color={MAIN_CONFIG.COLORS.fillWhite} width={20} height={20} />
                                    </CTAButton>

                                </div>
                            </div>
                            {pandingSycnSFToFE && (
                                <section>
                                    <hr className="H5HeadingDivider" />
                                    {(isLoading) ? <div className="skeleton-loader__container"><TableListingLoader /></div> : <div>
                                        <Table
                                            id={'applicant-screen__clients-table'}
                                            columnData={columnDataFESync && columnDataFESync}
                                            tableData={dataFE}
                                            showSelect={false}
                                        />
                                        <Paginator
                                            currentPage={currentPage?.frontendToSalesforceSync}
                                            totalRecords={currentTotalRecords?.frontendToSalesforceSync}
                                            perPage={perPage?.frontendToSalesforceSync?.value}
                                            handlePageClick={(page) => handlePagination(page, FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_TO_SALESFORCE_SYNC)}
                                        />
                                    </div>}
                                </section>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="frontend-to-salesforce-sync-menu">
                <div className="frontend-to-salesforce-sync">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <div className="header-container">
                                <div className="H5DesktopGreen">
                                    Frontend application sync to Elements ({currentTotalRecords.frontendApplicationSyncToElements})
                                </div>
                                <div className='refresh_btn'>
                                    <CTAButton id='search' type='primary' onClick={apiCallFrontendApplicationSyncToElements}>
                                        <Refresh color={MAIN_CONFIG.COLORS.fillWhite} width={20} height={20} /> Refresh
                                    </CTAButton>
                                    <CTAButton className='toggle_section' id='search' type='primary' onClick={() => setFrontendAppToElements(!frontendAppToElements)}>
                                        <ArrowDown color={MAIN_CONFIG.COLORS.fillWhite} width={20} height={20} />
                                    </CTAButton>
                                </div>
                            </div>
                            {frontendAppToElements && (
                                <section className = {currentTotalRecords?.frontendApplicationSyncToElements > 10 && 'salesforce-sync-margin'}>
                                    <hr className="H5HeadingDivider" />
                                    {(isLoading) ? <div className="skeleton-loader__container"><TableListingLoader /></div> : <div>
                                        {currentTotalRecords?.frontendApplicationSyncToElements > 10 && <div className='admin-table__record-per-page'>
                                            <RecordPerPage
                                                setItemCountOnPage={(page) => updatePerPage(page, FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_APPLICATION_SYNC_TO_ELEMENTS)}
                                                itemCountOnPage={perPage?.frontendApplicationSyncToElements}
                                                setCurrentPage={(page) => updateCurrentPage(page, FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_APPLICATION_SYNC_TO_ELEMENTS)}
                                                page={'admin-application-sync-to-elements'}
                                                isScholarshipPage={true}
                                            />
                                        </div>}
                                        <Table
                                            id={'applicant-screen__clients-table'}
                                            columnData={columnDataModifiedApplication && columnDataModifiedApplication}
                                            tableData={modifidAppData}
                                            showSelect={false}
                                        />
                                        <Paginator
                                            currentPage={currentPage?.frontendApplicationSyncToElements}
                                            totalRecords={currentTotalRecords?.frontendApplicationSyncToElements}
                                            perPage={perPage?.frontendApplicationSyncToElements?.value}
                                            handlePageClick={(page) => handlePagination(page, FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_APPLICATION_SYNC_TO_ELEMENTS)}
                                        />
                                    </div>}
                                </section>)}
                        </div>
                    </div>
                </div>
            </div>


            <div className="frontend-to-salesforce-sync-menu">
                <div className="frontend-to-salesforce-sync">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <div className="header-container">
                                <div className="H5DesktopGreen">
                                    Frontend contact sync to Elements ({currentTotalRecords?.frontendContactToElements})
                                </div>
                                <div className='refresh_btn'>
                                    <CTAButton id='search' type='primary' onClick={apiCallFrontendContactSyncToElements}>
                                        <Refresh color={MAIN_CONFIG.COLORS.fillWhite} width={20} height={20} /> Refresh
                                    </CTAButton>
                                    <CTAButton className='toggle_section' id='search' type='primary' onClick={() => setFrontendContactToElements(!frontendContactToElements)}>
                                        <ArrowDown color={MAIN_CONFIG.COLORS.fillWhite} width={20} height={20} />
                                    </CTAButton>
                                </div>
                            </div>
                            {frontendContactToElements && (
                                <section className = {currentTotalRecords?.frontendContactToElements > 10 && 'salesforce-sync-margin'}>
                                    <hr className="H5HeadingDivider" />
                                    {(isLoading) ? <div className="skeleton-loader__container"><TableListingLoader /></div> : <div>
                                        {currentTotalRecords?.frontendContactToElements > 10 && <div className='admin-table__record-per-page'>
                                            <RecordPerPage
                                                setItemCountOnPage={(page) => updatePerPage(page, FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_CONTACT_TO_ELEMENTS)}
                                                itemCountOnPage={perPage?.frontendContactToElements}
                                                setCurrentPage={(page) => updateCurrentPage(page, FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_CONTACT_TO_ELEMENTS)}
                                                page={'admin-contact-sync-to-elements'}
                                                isScholarshipPage={true}
                                            />
                                        </div>}
                                        <Table
                                            id={'applicant-screen__clients-table'}
                                            columnData={columnDataModifiedContect && columnDataModifiedContect}
                                            tableData={modifidContactData}
                                            showSelect={false}
                                        />
                                        <Paginator
                                            currentPage={currentPage?.frontendContactToElements}
                                            totalRecords={currentTotalRecords?.frontendContactToElements}
                                            perPage={perPage?.frontendContactToElements?.value}
                                            handlePageClick={(page) => handlePagination(page, FRONTEND_TO_SALESFORCE_SYNC_OBJECT.FRONTEND_CONTACT_TO_ELEMENTS)}
                                        />
                                    </div>}
                                </section>)}
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="frontend-to-salesforce-sync-menu">
                <div className="frontend-to-salesforce-sync">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <div className="header-container">
                                <div className="H5DesktopGreen">
                                    Rows in frontend database  ({currentTotalRecords?.rowsInFrontend})
                                </div>
                                <div className='refresh_btn'>
                                    <CTAButton id='search' type='primary' onClick={apiCallRowsFrontend}>
                                        <Refresh color={MAIN_CONFIG.COLORS.fillWhite} width={20} height={20} /> Refresh
                                    </CTAButton>
                                    <CTAButton className='toggle_section' id='search' type='primary' onClick={() => setRowInFE(!rowInFE)}>
                                        <ArrowDown color={MAIN_CONFIG.COLORS.fillWhite} width={20} height={20} />
                                    </CTAButton>
                                </div>
                            </div>
                            {rowInFE && (
                                <section>
                                    <hr className="H5HeadingDivider" />
                                    {(isLoading) ? <div className="skeleton-loader__container"><TableListingLoader /></div> : <div>
                                    <Table
                                            id={'applicant-screen__clients-table'}
                                            columnData={columnDataDataRows && columnDataDataRows}
                                            tableData={rowsFE}
                                            showSelect={false}
                                        />
                                    </div>}
                                </section>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            </> 
            )}

            {/*  */}
        </div>
    )
}