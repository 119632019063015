import React, { useState, useEffect } from 'react';
import ApplicationReviewText from '../ApplicationReviewText/ApplicationReviewText'
import UserBadge from '../../UserBadge/UserBadge';
import CTAButton from '../../../Components/CTAButton/CTAButton';
import './RecommendationDetails.scss'
import { skipConfigIds, skipConfigValue } from '../../../Utility/ApplicationFormUtility';

/**
 *
 * @param {object} data
 * @param {function} handleClose
 * @returns {JSX.Element}
 */
export default ({
	details = {},
	handleClose = () => {},
	name,
	email,
	setModalState = () => { }
}) => {
	var srno = 0
	const {applicant_recommendations} = details;

	const filterQuestions = ()=> {

		let recordIds = skipConfigIds(details.recommendation_questions, [])
		let recordValue = skipConfigValue(recordIds, [])

		return recordValue
	}

	return(
		<div id='review-details-page__recommendation-modal-content'>
			<div className="review-details-page-popup__user-details">
				<div className="review-details-page-popup__profile-icon">
					<div id='account-menu__profile-icon'>
						<UserBadge
							firstName={applicant_recommendations.recommender_ff__c}
						/>
					</div>
				</div>
				<div className="review-details-page-popup__profile-info">
					<div className="review-details-page-popup__profile-name application-review-attachment__heading">
						{name}
					</div>
					<div className="review-details-page-popup__profile-email application-review-attachment__small">
						{email}
					</div>
				</div>
			</div>

			{
				filterQuestions()?.map((item, index) => {
					if(item.answer_type__c !== 'Output Message'){
				        srno = srno + 1
				    }
					return(
						<ApplicationReviewText
							ordinal={ srno }
							heading={ item.question_label__c }
							content={ item }
							dataType={ item.answer_type__c }
							desciptionQuestion= {item.question_description__c}
							documents={ item.documents ? item.documents : item.document_name__c ? [{doc_name__c: item.document_name__c, doc_url__c: item.document_url__c}] : [] }
							wordCount={ item.word_count__c}
							isFirst={ index === 0 }
							key={ item.sfid }
							recommendation={true}
							setModalState={setModalState}
						/>
					)
				})
			}
			<div className="review-details-page-popup__footer">
				<CTAButton id='review-details_close-btn' type='tertiary' onClick={handleClose}>
					Close
				</CTAButton>
			</div>
		</div>
	);
};