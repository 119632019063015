import React, { useState, useEffect, useRef } from 'react';
import cookies from 'next-cookies';
import dynamic from 'next/dynamic'
import AppPage from "../../../Core/AppPage";
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import FilterSelector from '../../../Components/FilterSelector/FilterSelector';
import { MAIN_CONFIG, countryList } from '../../../../config/main';
import { debounce } from 'throttle-debounce';
import WordMark from '../../../Components/SVG/WordMark';
import ImagePlaceholderForCard from '../../../Components/SVG/ImagePlaceholderForCard';
import FilterToggle from '../../../Components/SVG/FilterToggle';

const Footer = dynamic(
    () => import("../../../Components/Footer/Footer"),
    { loading: () => <p>...</p> }
)

import FormatNumber from '../../../Utility/FormatNumber';

import { formatTags, checkMarketPlace } from '../../../Utility/HelperFunctions';

const Profile = dynamic(
    () => import("../../../Components/ApplicantProfile/Profile"),
    { loading: () => <p>...</p> }
)

const ChatList = dynamic(
    () => import("../../../Components/ChatList/ChatList"),
    { loading: () => <p>...</p> }
)

const Gradebook = dynamic(
    () => import("../../../Components/ApplicantGradeBook/Gradebook"),
    { loading: () => <p>...</p> }
)

const Tasks = dynamic(
    () => import("../../../Components/ApplicantTasks/ApplicantTasks"),
    { loading: () => <p>...</p> }
)

const SavedScholarships = dynamic(
    () => import("../../../Components/ApplicantSavedScholarships/ApplicantSavedScholarships"),
    { loading: () => <p>...</p> }
)

const Donors = dynamic(
    () => import("../../../Components/ApplicantProfile/Donors/Donors"),
    { loading: () => <p>...</p> }
)

const Disbursements = dynamic(
    () => import("../../../Components/ApplicantProfile/Disbursements/Disbursements"),
    { loading: () => <p>...</p> }
)

const Resources = dynamic(
    () => import("../../../Components/ApplicantProfile/Resources/Resources"),
    { loading: () => <p>...</p> }
)

import { ScholarshipCard } from '../../../Components/ScholarshipCard/ScholarshipCard';

const ApplicantApplication = dynamic(
    () => import("../../../Components/ApplicantApplication/ApplicantApplication"),
    { loading: () => <p>...</p> }
)

import "./applicant-home-page.scss";
const FlashMessage = dynamic(
    () => import('../../../Components/FlashMessage/Message'),
    { loading: () => <p>...</p> }
)

import { CardListingLoader } from '../../../Components/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { reduxPage } from '../../../Core/store'
import { useRouter } from 'next/router';
import { isEmpty, isUndefined } from 'lodash';
import SidebarModal from '../../../Components/SideBarModal/SidebarModal';
import { handleKeyDown, alretMsg } from '../../../Utility/ApplicationFormUtility';
import CTAImageTag from '../../../Components/CTAImageTag/CTAImageTag';

const Loader = dynamic(
    () => import('../../../Components/Loader/Loader'),
    { loading: () => <p>...</p> }
)

const CTAButton = dynamic(
    () => import('../../../Components/CTAButton/CTAButton'),
    { loading: () => <p>...</p> }
)

const FieldCheckbox = dynamic(
    () => import('../../../Components/SVG/FieldCheckbox'),
    { loading: () => <p>...</p> }
)

const Globe = dynamic(
    () => import('../../../Components/SVG/Globe'),
    { loading: () => <p>...</p> }
)

const ScholarshipCTA = dynamic(
    () => import('../../../Components/ScholarshipCTA/ScholarshipCTA'),
    { loading: () => <p>...</p> }
)



const ApplicantHome = ({
    pageData,
    accountData,
    userContext,
    setActiveHeaderNav,
    activeTab = false,
    setActiveTab = () => { },
    setModalState = () => { },
    setAccountLoaderVisible = () => { }
}) => {
    const API = new KaleidoscopeAPI({});
    const { marketPlaceData } = pageData;
    const [profile, setProfile] = useState({});
    const [scholarships, setScholarships] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataLoad, setDataLoad] = useState(false)
    const [submitted, setSubmitted] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [totalScholarship, setTotalScholarship] = useState(0);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filterButtonScholarships, setFilterButtonScholarships] = useState([])
    const [scholarshipButtonActive, setScholarshipButtonActive] = useState("Programs")
    const [donationTabActive, setDonationTabActive] = useState(false);
    const [showProgramFilters, setShowProgramFilters] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [programFilterActiveTab,setProgramFilterActiveTab] = useState('All')
    const [programFilterActiveTabForResponce, setProgramFilterActiveTabForResponce] = useState('')
    const [isOpenSweetAlert, setIsOpenSweetAlert] = useState(false)




    let accountId = cookies('currentAccount').currentAccount
    let token = cookies('token').token


    const { account } = marketPlaceData;
    
    const showUnsubscribeConfirmationPopup = localStorage.getItem('show_unsubscribe_confirmation') || false
    
    const messageTrigger = useRef()
    // const dispatch = useDispatch()
    // dispatch(applicationIsLoading(true))
    const router = useRouter();
    let route = router.query
    if (route.indexId) {
        // localStorage.setItem("marketPlace", route.indexId)
        localStorage.setItem("marketPlace", route.indexId)
        document.cookie = `marketPlace=${route.indexId};path=/`;
    }
    const [
        activeFilters,
        setActiveFilters,
    ] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [activeTab])
    
    useEffect(() => {
        sessionStorage.removeItem('previousPath')
        sessionStorage.removeItem('redirectApply')
        sessionStorage.removeItem("userEmail")
        if (showUnsubscribeConfirmationPopup === 'true') {
            alretMsg("", setIsOpenSweetAlert, () => { }, false, '', () => {
                localStorage.setItem('show_unsubscribe_confirmation', false);
            },
                'Email Unsubscribe Confirmation',
                true,
                false
            );
            const timeoutId = setTimeout(() => {
                setIsOpenSweetAlert(false);
                localStorage.setItem('show_unsubscribe_confirmation', false);
            }, 30000);
            return () => clearTimeout(timeoutId);
        }
    }, []);
    const { error_message } = router.query;

    useEffect(() => {
      if (error_message) {
        // If an error message exists in the query params, display it in a toast error message.
        toast.error(<FlashMessage message={error_message} type="error" />);
      }
    }, [error_message]);

    let selectedAccount = accountData.availableAccounts ? accountData.availableAccounts.filter(account => (account.url_name__c === route?.indexId || account.sfid === cookies('currentAccount').currentAccount && !route?.indexId)) : [];

    const checkLogout = userContext === MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT

    if (checkLogout || !route.indexId) {
        localStorage.marketPlace = ""
        document.cookie = 'marketPlace=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    }


    const handleProgramTabClick = (val, tabval = "") => {
        setDataLoad(true);
        setProgramFilterActiveTab(val);
        setProgramFilterActiveTabForResponce(tabval)
        if (tabval !== "" ) {
            fetchScholarships(1, true, false,  tabval);
          }
        else {
          fetchScholarships(1, true, false);
        }
      };
    

    /**
     * Fetch user profile if Applicant is loggedin
     * @returns {Object}
     */
    useEffect(() => {
        (async () => {
            if (activeTab === MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE) {
                if (token) {
                    await API.fetchProfile({ token: accountData.token });
                }
            setLoading(true);

                fetchScholarships(1, false, scholarshipButtonActive === "Donate" ? true : false, programFilterActiveTab == 'All' ? '' : programFilterActiveTabForResponce);
            }
            // setLoading(false);

        })();
    }, [activeFilters, activeTab, searchValue]);

    React.useEffect(() => {
        setActiveHeaderNav('explore')
    }, [])

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const checkUserContext = cookies('context').context

    useEffect(() => {
        if (!checkUserContext) {
            document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'marketPlace=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        }
    }, []);

    /**
     * Marks filter as active and retrieves
     * updated list of resources
     *
     * @param {object[]} filters
     * @param {string} filters.type
     * @param {string} filters.value
     */

    const addFilters = filters => {
        // let newFilters = {
        //     ...activeFilters,
        // }

        // for (let i = 0, total = filters.length; i < total; i++) {
        //     const filter = filters[i]

        //     if (!filter.value) {
        //         delete newFilters[filter.type]
        //     } else {
        //         newFilters[filter.type] = filter.value
        //     }
        // }

        setActiveFilters(filters)
    }

    const showFilters = userContext === MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT ? !route.indexId : isEmpty(accountId || route.indexId)

    const handleDonationTabClick = (e, showDonation) => {
        setDonationTabActive(showDonation)
        e.preventDefault()
        // fetchScholarships(1, false, showDonation)
        setActiveFilters({})
    }

    /**
     * Removes filters and reloads lists of
     * resources
     *
     * @param {array} filterTypes
     */

    const removeFilters = filterTypes => {
        const currentFilters = activeFilters

        for (let i = 0, total = filterTypes.length; i < total; i++) {
            delete currentFilters[filterTypes[i]]
        }

        setActiveFilters(Object.assign({}, currentFilters))
    }

    // const handleSearch = (name, value) => {
    //     setSearchValue(value)
    // }

    const handleSearch = debounce(
        MAIN_CONFIG.SEARCH.DELAY,
        false,
        (value) => {
            setSearchValue(value)
        }
    );


    /**
     * Saved Scholarships.
     * @params {object} data
     */
    // const handleSaved = async (e, id) => {
    //     // setSubmitted(true)
    //     e.preventDefault()
    //     let updatedScholarships = scholarships.map(item => {
    //         if (item.sfid == id) {
    //             return { ...item, 'is_scholarship_bookmarked': true }; //gets everything that was already in item, and updates "done"
    //         }
    //         return item; // else return unmodified item
    //     })
    //     setScholarships(updatedScholarships)
    //     const response = await API.updateSavedScholarships({ token: accountData.token, ID: id })
    //     // if (response.success) {

    //     //     // toast.success(<FlashMessage message={response.message} />);
    //     // } else {
    //     //     // toast.error(<FlashMessage message={response.message} type="error" />);
    //     // }
    //     // setSubmitted(false)
    // }

    /**
     * Remove Saved Scholarships.
     * @params {object} data
     */

    const labelForPrivateMarket = {
        singular: 'Program',
        plural: 'Programs',
    }
    const label = {
        singular: 'Program',
        plural: 'Programs',
    }
    // const handleRemove = async (e, id) => {
    //     // setSubmitted(true)
    //     e.preventDefault()
    //     if (activeTab === MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE) {
    //         let updatedScholarships = scholarships.map(item => {
    //             if (item.sfid == id) {
    //                 return { ...item, 'is_scholarship_bookmarked': false }; //gets everything that was already in item, and updates "done"
    //             }
    //             return item; // else return unmodified item
    //         })
    //         setScholarships(updatedScholarships)
    //     } else {
    //         setScholarships(scholarships.filter(item => item.sfid !== id));
    //     }
    //     const response = await API.removeSavedScholarships({ token: accountData.token, ID: id })
    //     // if (response.success) {

    //     //     // toast.success(<FlashMessage message={response.message} />);
    //     // }
    //     // setSubmitted(false)
    // }

    /**
     * Render ScholarshipCard for each scholarship
     * @returns {JSX.Element}
     */
    const renderScholarshipCards = () => scholarships.map((scholarship) => {
        let url = scholarship?.pre_url_name__c ? `/${scholarship.pre_url_name__c}/${scholarship.url_name__c || scholarship.sfid}` : `/scholarships/${scholarship?.sfid}`
        let elementsProgramUrl = MAIN_CONFIG.ELEMENTS_URLS.APPLICANT.SCHOLARSHIP(scholarship?.url_name__c)
        return (
            <ScholarshipCard key={scholarship.id}
                id={scholarship.sfid || scholarship.heroku_id__c}
                logo={scholarship.scholarshiplogourl__c}
                title={scholarship.name}
                color={scholarship.primary_branding_color__c || scholarship.account?.primary_color__c}
                excerpt={scholarship.preview_text__c}
                tags={formatTags(scholarship.scholarship_category__c)}
                due_date={scholarship.deadline_date_time || ''}
                award={scholarship.scholarship_fund_amount__c}
                url={url}
                userContext={userContext}
                setSubmitted={setSubmitted}
                showCurrencySymbol={scholarship.show_currency_symbol__c}
                showFundAmount={scholarship.show_fund_amount__c}
                openDate={scholarship.open_date__c}
                statusScholarship={scholarship.status__c}
                minimumFundAmount={scholarship.minimum_fund_amount__c}
                maximumFundAmount={scholarship.maximum_fund_amount__c}
                scholarshipHeaderLogo={scholarship.scholarship_header_logo_url__c}
                scholarshipFormType={scholarship.form_type__c}
                scholarshipFundAmount={scholarship.scholarship_fund_amount__c}
                totalDonatedAmount={scholarship.total_donation_received__c}
                lastDonatedAmount={scholarship.last_donation_received_at__c}
                donationProgress={scholarship.donation_progress__c}
                showDonorScholarshipTile={scholarship.donation_information_visibility__c}
                autoApplyScholarship={scholarship.autoapply_scholarship__c}
                showOpenDate={scholarship.show_open_date_in_card__c}
                open_date={scholarship.open_date}
                sponserLogo={scholarship.sponsor_logo_image__c||''}
                sponcerImage={scholarship.about_the_sponsor_image__c||''}
				headerRedirectionUrl={scholarship.header_redirection_url__c||''}
                sponserDescription={scholarship.description__c||''}
                elementsProgramUrl={elementsProgramUrl}
                isElementsProgram={scholarship?.elements_program__c}
            />
        )
    })


    // TODO: Remove this once it is available from the API
    const columnData = {
        table_headers: [
            { field_api_name__c: 'name', column_name__c: 'Gradebook Name', enable_sort__c: true },
            { field_api_name__c: 'year__c', column_name__c: 'Year', enable_sort__c: true },
            { field_api_name__c: 'semester__c', column_name__c: 'Semester', enable_sort__c: true },
            { field_api_name__c: 'semester_gpa__c', column_name__c: 'G.P.A', enable_sort__c: true },

        ]
    };

    const showLogo = !checkLogout ? (selectedAccount[0] ? selectedAccount[0].account_logo_url__c : account?.account_logo_url__c) : accountData?.currentAccount?.account_logo_url__c

    const fetchHeaderDetails = () => {

        var primaryColorConvert = accountData && accountData.currentAccount && accountData.currentAccount.primary_color__c ? accountData.currentAccount.primary_color__c.toLowerCase() : ''

        return <>
            {!showFilters && <div className="applicant-home__leading__individual-account-header">

                <div className="applicant-home__leading__individual-account-header__top">
                    <div className="applicant-home__leading__individual-account-header__logo-container">
                        {
                            showLogo ? (!checkLogout ?
                                <CTAImageTag data={selectedAccount[0] ? selectedAccount[0].account_logo_url__c : account?.account_logo_url__c} title={selectedAccount[0] ? selectedAccount[0].name : account?.name} className="scholarship-logo" /> : <CTAImageTag data={accountData.currentAccount.account_logo_url__c} title={accountData.currentAccount.name} className="scholarship-logo" />) : <ImagePlaceholderForCard color={MAIN_CONFIG.COLORS.fillGreen} width={100} height={71.5} className="scholarship-logo-svg" />
                        }
                    </div>
                    <div className="applicant-home__leading__individual-account-header__content-container">
                        <div className="applicant-home__leading__individual-account-header__content-container__title H1DesktopGreen" style={primaryColorConvert ? { color: `${primaryColorConvert == '#ffffff' ? '#000000' : primaryColorConvert || '#008067'}` } : { primaryColorConvert: `#008067` }}>
                            {!checkLogout ? (selectedAccount[0] ? selectedAccount[0]?.name : account?.name) : accountData?.currentAccount?.name}
                        </div>
                        <div className="applicant-home__leading__individual-account-header__content-container__subtitle CTABlack">
                            {`${FormatNumber(totalScholarship)} ${(totalScholarship === 1) ? labelForPrivateMarket?.singular : labelForPrivateMarket?.plural}`}
                        </div>
                    </div>
                </div>

                <div className="applicant-home__leading__individual-account-header__bottom">
                    <div className='applicant-home__leading__individual-account-header__bottom__content-container__description BodyDefaultRegularBlack'>
                        {checkLogout ? accountData?.currentAccount?.description : selectedAccount[0] ? selectedAccount[0]?.description : account?.description}
                    </div>

                </div>
            </div>}

        </>
    }


    /**
     * Determines which content should be shown based on active tab.
     * @returns {JSX.Element}
     */
      const handleSeeMore = () => {
        const isDonateActive = scholarshipButtonActive === "Donate";
        const programFilter = programFilterActiveTab === "All" ? "" : programFilterActiveTabForResponce;
        fetchScholarships(page, true, isDonateActive, programFilter);
      }
    const renderMainPageContent = () => {

        <Loader loading={loading} />
        switch (activeTab) {
            case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE:
                return (
                    <div className="applicant-home__content">
                        <div className="applicant-home__leading">
                            {fetchHeaderDetails()}


                            <FilterSelector
                                userContext={userContext}
                                className="applicant-home__filter"
                                label={label}
                                count={totalScholarship || 0}
                                filters={filters}
                                addFilters={addFilters}
                                removeFilters={removeFilters}
                                globalSearch={true}
                                handleSearch={handleSearch}
                                stateSelector={true}
                                data={totalScholarship || 0}
                                activeFilters={activeFilters}
                                showFilters={showFilters}
                                filterButtonScholarships={filterButtonScholarships}
                                handleDonationTabClick={handleDonationTabClick}
                                scholarshipButtonActive={scholarshipButtonActive}
                                setScholarshipButtonActive={setScholarshipButtonActive}
                                setSubmitted={setSubmitted}
                                accountData={accountData}
                                account={account}
                                donationTabActive={donationTabActive}
                                showProgramFilters={showProgramFilters}
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                                setAccountLoaderVisible={setAccountLoaderVisible}
                                setDataLoad={setDataLoad}
                                fetchScholarships = {fetchScholarships}
                                programFilterActiveTab = {programFilterActiveTab}
                                setProgramFilterActiveTab={setProgramFilterActiveTab}
                                handleProgramTabClick={handleProgramTabClick}
                    
                            />

                            {/* <button onClick={openModal}>Open<FilterToggle /></button> */}
                            {/* <SidebarModal filters={filters} addFilters={addFilters} removeFilters={removeFilters} setDataLoad = {setDataLoad} showFilters = {showFilters}/> */}
                            <Loader loading={dataLoad} />

                            <div className="applicant-home__scholarships">
                                {(scholarships.length < 1 && loading) ? <CardListingLoader /> : (scholarships.length > 0 ? renderScholarshipCards() : <p className="no-data-to-display__description">No Record Found</p>)}
                            </div>

                            <div className="cardLoadingMessage">
                                {
                                    hasMore &&
                                        <a className='see-more-program-btn' tabIndex={0} role='button' onKeyDown={(e)=>handleKeyDown(e,handleSeeMore)} aria-label="See More Program Button" onClick={handleSeeMore} >
                                            <div>See more</div>
                                        </a>
                                        // :
                                        // scholarships.length > 0 && <div className="cardLoadingEndMessage">
                                        //     <CTAButton type='tertiary' disabled>
                                        //         Yay! You have seen it all
                                        //     </CTAButton>
                                        // </div>
                                }
                            </div>
                        </div>
                    </div>
                )


            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.TASKS:
            //     return <Tasks
            //         accountData={accountData}
            //         data={profile}
            //         setModalState={setModalState}
            //     />

            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.MESSAGE:
            //     return <ChatList
            //         id='donor-header__chat-menu'
            //         className='open'
            //         showChatBox={true}
            //         currentUser={pageData.userInfo}
            //         setModalState={setModalState}
            //         token={accountData.token}
            //         stateHandler={() => { }}
            //         trigger={messageTrigger}
            //     />

            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROFILE:
            //     return <Profile
            //         accountData={accountData}
            //         marketPlaceData={marketPlaceData}
            //         data={profile}
            //     />


            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DONOR:
            //     return <Donors
            //         accountData={accountData}
            //         data={profile}
            //         setModalState={setModalState}
            //     />


            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.GRADEBOOK:
            //     return <Gradebook
            //         accountData={accountData}
            //         data={profile}
            //         setModalState={setModalState}
            //         userContext = {userContext}
            //     />


            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DISBURSEMENTS:
            //     return <Disbursements
            //         data={profile}
            //         setModalState={setModalState}
            //         accountData={accountData} />

            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.RESOURCES:
            //     return <Resources
            //         data={profile}
            //         accountData={accountData} />

            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SAVED:
            //     return <SavedScholarships
            //         data={profile}
            //         accountData={accountData} />

            // case MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.APPLICATIONS:
            //     return <ApplicantApplication
            //         data={profile}
            //         accountData={accountData}
            //         activeTab={activeTab}
            //         setActiveTab={setActiveTab} />
        }

    }


    const fetchScholarships = (page, loaderChange, showDonation = false , active_tab = "") => {
        if (loaderChange) {
            setSubmitted(true);
            setLoading(true);
        } else {
            setLoading(true);
        }
        (async () => {
            let newFilter = JSON.parse(JSON.stringify(activeFilters))
            if(active_tab !== ''){
                newFilter.active_tab = active_tab
            }
            const result = await API.fetchScholarships({
                options: { page: page, per_page: 15 },
                token: accountData.token,
                userContext,
                filters: JSON.stringify(newFilter),
                searchValue: searchValue,
                route: route.indexId || '',
                donation_forms: showDonation,                
            });

            setDataLoad(false)

            if (result.success === false || result.status === 500) {
                localStorage.marketPlace = ""
                document.cookie = 'marketPlace=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
                toast.error(<FlashMessage message={result.message || "Something went wrong."} />);
            } else {

                if (result.error) {
                    return false
                }

                if (route.indexId) {
                    let selectedAccount = accountData.availableAccounts ? accountData.availableAccounts.filter(account => account.url_name__c === route.indexId) : [];
                    let sfid = ''
                    if (selectedAccount.length > 0) {
                        sfid = selectedAccount[0].sfid
                        document.cookie = `currentAccount=${sfid};path=/`;
                    }
                    setShowProgramFilters(result.show_program_filters)
                }

                if (accountId) {
                    setShowProgramFilters(result.show_program_filters)
                }

                if (result.show_donation_forms) {
                    setFilterButtonScholarships(["Programs", "Donate"])
                }
                setTotalScholarship(result.pagination?.totalRecords)
                if (result.scholarships.length === 0) {
                    if (page === 1) {
                        setScholarships(result.scholarships);
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                } else {
                    if (page === 1) {
                        setScholarships(result.scholarships);
                    } else {
                        setScholarships(scholarships.concat(result.scholarships));
                    }
                    setPage(page + 1)
                    setHasMore(true)
                }
                if (result.scholarships.length < 15) {
                    setHasMore(false)
                }
            }
            setLoading(false);
            setSubmitted(false);
        })();
    };

    // const filters = [
    //     {
    //         type: "grade_level__c",
    //         label: "Grade Level",
    //         options: MAIN_CONFIG.APPLICANT.GRADE_LEVEL_OPTIONS,
    //         isMultiple: true,
    //     },
    //     {
    //         type: "minimum_gpa__c",
    //         label: "GPA",
    //         optionType: 'range',
    //         options: MAIN_CONFIG.APPLICANT.GPA_RANGE,
    //     },
    //     {
    //         type: "scholarship_fund_amount__c",
    //         label: "Amount",
    //         options: MAIN_CONFIG.APPLICANT.AWARD_AMOUNT,
    //         getCount: value => {
    //             /*
    //                     * TODO:
    //                     *
    //                     * Determine if counts should be affected by applied filters, or not
    //                     */
    //             return FormatNumber(
    //                 scholarships
    //                     .filter(scholarship => {
    //                         if (value === 0) {
    //                             return scholarship.scholarship_fund_amount__c === 0 || !scholarship.scholarship_fund_amount__c
    //                         } else {
    //                             return scholarship.scholarship_fund_amount__c >= value || scholarship.maximum_fund_amount__c >= value
    //                         }
    //                     })
    //                     .length
    //             )
    //         }
    //     },
    //     {
    //         type: 'nested',
    //         label: 'More Filters',
    //         optionType: 'modal',
    //         nestedFilters: [
    //             {
    //                 heading: 'No essay',
    //                 description: 'Only show scholarships that do not require essays',
    //                 type: 'essay_questions_available__c',
    //                 optionType: 'toggle',
    //                 options: MAIN_CONFIG.APPLICANT.NO_ESSAY,
    //             },
    //             {
    //                 heading: 'Categories',
    //                 type: 'scholarship_category__c',
    //                 options: MAIN_CONFIG.APPLICANT.CATEGORIES,
    //                 optionType: 'checkbox',
    //                 isMultiple: true,
    //             },
    //             {
    //                 heading: 'Citizenship',
    //                 type: 'citizenship__c',
    //                 options: countryList,
    //                 optionType: 'multiselect',
    //                 isMultiple: true,
    //             },
    //         ],
    //     }
    // ];


    const filters = [
        {
            type: "program_type__c",
            label: "Program Type",
            options: MAIN_CONFIG.APPLICANT.PROGRAM_TYPE,
            isMultiple: true,
        },
        {
            type: "grade_level__c",
            label: "Grade Level",
            options: MAIN_CONFIG.APPLICANT.GRADE_LEVEL_OPTIONS,
            isMultiple: true,
        },
        {
            type: "amount__c",
            label: "Amount",
            options: MAIN_CONFIG.APPLICANT.AWARD_AMOUNT,
            isMultiple: true,
        },
        {
            type: "minimum_gpa__c",
            label: "GPA",
            optionType: 'range',
            options: MAIN_CONFIG.APPLICANT.GPA_RANGE,
        }
    ];
    return (
        <div className="applicant-home background-color__light-grey">
            {renderMainPageContent()}
            <ToastContainer />
            {isOpenSweetAlert}
            {userContext === MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT && (accountData && !accountData.currentAccount) ? <Footer /> : null}
        </div>
    );
};

const ApplicantHomePage = AppPage(ApplicantHome);

// export default ApplicantHomePage;

export default reduxPage(ApplicantHomePage)