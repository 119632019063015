import React, { Component } from 'react';
import DonorHomePage from '../Modules/Pages/Donor/DonorHomePage/DonorHomePage';
import { MAIN_CONFIG } from '../config/main';
import Custom404Page from './404';
import ReviewerHomePage from '../Modules/Pages/Reviewer/ReviewerHomePage/ReviewerHomePage';
import RecommenderHomePage from '../Modules/Pages/RecommenderHomePage/RecommenderHomePage';
import ApplicantHomePage from '../Modules/Pages/Applicant/ApplicantHomePage/ApplicantHomePage';
import UniversityStaffHomePage from '../Modules/Pages/UniversityStaff/UniversityStaffHomePage/UniversityStaffHomePage';
import SuperAdminPage from '../Modules/Pages/SuperAdminPage/SuperAdminPage';
const { USER_CONTEXT } = MAIN_CONFIG;
import TagManager from 'react-gtm-module'
import cookies from 'next-cookies';
import { handleRedirectHrefUrl } from '../Modules/Utility/HelperFunctions';


export default class DonorHomeContainer extends Component {

    componentDidMount() {
        const userContext = cookies('context').context
        if (userContext != 'DONOR') {
            if (process.env.NEXT_PUBLIC_BEACON_KEY) {
                window.Beacon('init', process.env.NEXT_PUBLIC_BEACON_KEY)
            } else if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production') {
                handleRedirectHrefUrl(`https://${window.location.hostname}${window.location.pathname}`);
            }
        }
        TagManager.initialize({ gtmId: "GTM-PGVDW9L" });
    }

    static async getInitialProps (context) {
        return DonorHomePage.getInitialProps(context, MAIN_CONFIG.PAGE_TYPES.DONOR.HOME);
    }

    render () {
        const { userContext, userAdminContext } = this.props;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                return  this.props.pageData && this.props.pageData.account === false ? <Custom404Page { ...this.props } message="Your account is not ready, please contact your administrator." /> : <DonorHomePage { ...this.props } />;
            case USER_CONTEXT.REVIEWER:
                return  this.props.pageData && this.props.pageData.account === false ? <Custom404Page { ...this.props } message="Your account is not ready, please contact your administrator." /> : <ReviewerHomePage { ...this.props } />;
            case USER_CONTEXT.RECOMMENDER:
            case USER_CONTEXT.NGB:
            case USER_CONTEXT.SCHOOLCOUNSELLOR:
            case USER_CONTEXT.ENDORSEDUSER:
            case USER_CONTEXT.THIRDPARTYCONTRIBUTOR:
            case USER_CONTEXT.VERIFIER:
                return <RecommenderHomePage { ...this.props } />;
            case USER_CONTEXT.APPLICANT:
            case USER_CONTEXT.TEAMMEMBER:
            case USER_CONTEXT.LOGGED_OUT:
                return <ApplicantHomePage { ...this.props } />
            case USER_CONTEXT.UNIVERSITYSTAFF:
            case USER_CONTEXT.HSSTAFF:
            case USER_CONTEXT.COLLEGESTAFF:
            case USER_CONTEXT.DETROITSF:
                return <UniversityStaffHomePage { ...this.props } />;
        }
        switch (userAdminContext) {
            case USER_CONTEXT.ADMIN:    
                return <SuperAdminPage {...this.props} />
            case USER_CONTEXT.LOGGED_OUT:
                return <ApplicantHomePage { ...this.props } />
        }
    }
}