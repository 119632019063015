import React, { useState, useEffect } from 'react';
import SpaceInvader from '../../SVG/SpaceInvader'
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import Table from '../../Table/Table';
import Paginator from '../../Paginator/Paginator';
import { TableListingLoader } from '../../Loader/Loader';
import Score from '../../SVG/Score';
import { Doughnut } from 'react-chartjs-2'
import CTAButton from '../../CTAButton/CTAButton';
import { chartDataFilter } from '../../../Utility/HelperFunctions'
import Refresh from '../../../Components/SVG/Refresh';
import { MAIN_CONFIG } from '../../../../config/main';


export default ({ accountData, setModalState }) => {
    const API = new KaleidoscopeAPI({});
    const [chart, setChart] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [currentTableData, setCurrentTableData] = useState([]);

    const [currentSortDirection, setCurrentSortDirection] = useState('');
    const [currentSortColumn, setCurrentSortColumn] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [currentTabTotalRecords, setCurrentTabTotalRecords] = useState(0); // Tracks current tab

    const columnData = {
        table_headers: [
            { field_api_name__c: 'first_name', column_name__c: 'First Name', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'last_name', column_name__c: 'Last Name', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'email', column_name__c: 'Email', enable_sort__c: true, enable_search__c: true }
        ]
    };


    const {user} = accountData

    useEffect(() => {
        updateTableData();
    }, [currentPage, currentSortColumn, currentSortDirection]);

    // useEffect(() => {
    //     (async () => {
    //         let response = await API.chartData({ token: accountData.token })
    //         if (response.chart_data) {
    //             let chartData = response.chart_data
    //             setChart(response.chart_data)
    //         }
    //     })();
    // }, [])

    /**
     * Fetch new scholarships based on sort request.
     * @param {string} columnName
     * @param {string} currentSortDirection
     */
    const handleSort = async (columnName, currentSortDirection) => {

        const newSortDirection = currentSortDirection.toUpperCase() === 'ASC' ? 'DESC' : 'ASC';
        setCurrentSortColumn(columnName);
        setCurrentSortDirection(newSortDirection);
    }

    const handleRefresh = async () => {
        setCurrentPage(1)
        setIsLoading(true)
        await updateTableData()
    }
    /**
     * Fetches the data for the specified page number.
     * @param {number} page
     */
    const handlePagination = page => {
        setCurrentPage(page)
        setIsLoading(true)
    }

    /**
     * Update the table data respecting sort, search, and pagination.
     * @returns {Promise<void>}
     */
    const updateTableData = async () => {
        const options = {
            page: currentPage,
            sort: currentSortColumn ? `${currentSortColumn} ${currentSortDirection}` : false
        }

        const updatedTableData = await API.activeUsers({
            token: accountData.token,
            options,
        });
        setIsLoading(false)
        // TODO: Determine why the property check is necessary to prevent error.
        const tableData = updatedTableData.users ? updatedTableData.users : [];
        const { pagination } = updatedTableData;
        setCurrentTabTotalRecords(pagination.totalRecords);
        // setCurrentPage(pagination.currentPage);
        setCurrentTableData(tableData);

    }

    return (
        <div className="admin-main">
            {/* <h1 className="H1DesktopGreen admin-welcome-message"><SpaceInvader id="user-account-page-user-icon" /> {`Welcome, ${user.first_name + " " + user.last_name}`}</h1> */}
            <div className="dashboard-statistics">
                <div className="active-users-cards">
                    <div className="H5DesktopGreen header-container">
                        <div>
                            Active Users ({currentTabTotalRecords})
                        </div>
                        <div>
                            <CTAButton id='search' type='primary' onClick={handleRefresh}>
                                <Refresh color={MAIN_CONFIG.COLORS.fillWhite} width={20} height={20} />
                                Refresh
                            </CTAButton>
                        </div>
                    </div>

                    <hr className="H5HeadingDivider" />
                    {(isLoading) ? <div className="skeleton-loader__container"><TableListingLoader /></div> : (
                        <Table
                            id={'active-users'}
                            showSelect={false}
                            columnData={columnData}
                            tableData={currentTableData}
                            handleSort={handleSort}
                            currentSortColumn={currentSortColumn}
                            currentSortDirection={currentSortDirection}
                        />
                    )}
                    <Paginator
                        currentPage={currentPage}
                        totalRecords={currentTabTotalRecords}
                        perPage={perPage}
                        handlePageClick={handlePagination}
                    />
                </div>

                <div className="cards">
                    {/* <div className="card-single">
                        <div className="section group">
                            <div className="col span_12_of_12">
                                <div className="H5DesktopGreen title-with-icons"><div>Browser</div><div><img src="https://img.icons8.com/color/48/000000/chrome--v1.png" /><img src="https://findicons.com/files/icons/1070/software/128/mozilla_firefox.png" /><img src="https://purepng.com/public/uploads/large/purepng.com-safari-iconsymbolsiconsapple-iosiosios-8-iconsios-8-7215225961106timx.png" /><img src="https://img.icons8.com/color/48/000000/ms-edge-new.png" /><img src="https://img.icons8.com/color/48/000000/safari--v1.png" /></div> </div>
                                <hr className="H5HeadingDivider" />
                                <div className="doughnut-browser-chart">
                                    <Doughnut data={chartDataFilter(chart, 'Browser Name')} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="card-single">
                        <div className="section group">
                            <div className="col span_12_of_12">
                                <div className="H5DesktopGreen title-with-icons"><div>Device Type</div><div><img src="https://img.icons8.com/ios/50/000000/monitor--v1.png" /> <img src="https://img.icons8.com/ios/24/000000/ipad.png" /><img src="https://img.icons8.com/ios/50/000000/broken-phone--v2.png" /></div></div>
                                <hr className="H5HeadingDivider" />
                                <div className="doughnut-browser-chart">
                                    <Doughnut data={chartDataFilter(chart, 'Device Type')} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="card-single">
                        <div className="section group">
                            <div className="col span_12_of_12">
                                <div className="H5DesktopGreen title-with-icons"><div>Operating System</div><div><img src="https://img.icons8.com/color/48/000000/windows-logo.png" /><img src="https://img.icons8.com/color/48/000000/mac-os--v1.png" /><img src="https://img.icons8.com/color/48/000000/linux.png" /></div></div>
                                <hr className="H5HeadingDivider" />
                                <div className="doughnut-browser-chart">
                                    <Doughnut data={chartDataFilter(chart, 'Operating System')} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}