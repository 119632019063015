import React, { useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import "./DocumentPreviewer.scss";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomIn from '../SVG/ZoomIn';
import ZoomOut from '../SVG/ZoomOut';

export default function DocumentPreviewer({ handleClose = () => { }, name = '', scanUrl = ''}) {
    const docs = [
        { uri: scanUrl }, // Remote file
    ];

    let lastDotIndex = scanUrl.lastIndexOf(".");
    let extension = scanUrl?.slice(lastDotIndex)?.toLowerCase();
    let hideCustomZoom = ['.docx', '.doc']

    const MyNoRenderer = () => {
        useEffect(() => {
            let element = document.getElementById('no-renderer');
            if (element) {
                const textNode = element.childNodes[0];
                const secondaryTextNode = element.childNodes[1]
                if (textNode) {
                  textNode.textContent = 'Sorry, this file type is not supported in the viewer';
                  secondaryTextNode.textContent = ''
                }
            }
          });
      };

      const renderDoc = () => {
        return (
            <DocViewer
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                config={{
                    header: {
                        overrideComponent: MyNoRenderer,
                    },
                }}
            />)
    }
    const renderDocViewer = () => {
        if(hideCustomZoom.includes(extension)){
            return <>{renderDoc()}</>
        }
            return (<TransformWrapper>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                        <div className='media-file_inner-container'>
                            <button className='cursorPointer' onClick={() => zoomOut()}>
                                <ZoomOut />
                            </button>
                            <button className='cursorPointer' onClick={() => zoomIn()}>
                                <ZoomIn />
                            </button>
                        </div>
                        <TransformComponent>
                            {renderDoc()}
                        </TransformComponent>
                    </>
                )}
            </TransformWrapper>)
    }
    const customClass = extension ==='.pdf' &&  "pdfHeader"

    const renderPreviewContent = () => {
        if(extension === '.pdf') {
            return <iframe src={scanUrl} title={name} style={{ width: "100%", height: "71vh" }} />
        } else {
            return renderDocViewer()
        }
    }
    return (
        <div className="document-viewer-result-page">
            <div className={`document-viewer-result-page__header ${customClass}`}>
                <div>
                    <h1 className='H1DesktopGreen document-viewer-result-title'>File</h1>
                </div>
                <div className="media-file_zoom-container">
                    <button className="event-head H6DesktopGrey close-btn" onClick={handleClose}>X</button>
                </div>
            </div>

            <div className='document-viewer-result-sub-title H6DesktopGray'>
                {name}
            </div>
            {renderPreviewContent()}
        </div>
    )
}