import React, { useState, useEffect } from 'react';
import Field from '../../Components/Field/Field';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import CTAButton from '../../Components/CTAButton/CTAButton';
import './form.scss';
import { getQuestionDataType } from '../../Utility/RecommendationDataTypeMapping';
import { parseHtml, formatAnswerOptions} from '../../Utility/HelperFunctions';
import { getQuestionAnswer, onChangeQuestionAnswer } from '../../Utility/ApplicationFormUtility';
import isEmpty from 'lodash/isEmpty';
import FlashMessage from '../../Components/FlashMessage/Message';
import {toast } from 'react-toastify';
import cookies from 'next-cookies'


export default ({
	token,
	handleClose = () => { },
	handleSubmit = () => { },
	deleteRecommendationQuestionDoc = () => { },
	questions = [],
	sfid,
	status,
	title,
	heroku_id
}) => {

	const API = new KaleidoscopeAPI({});

	const userContext = cookies('context').context

	const [questionData, setQuestionData] = useState(questions);

	const [errors, setErrors] = useState([]);
	/**
	 * Handle Application answer value changes
	 */
	const onChange = (name, value, videoDuration = '') => {

		// const isDocument = !!value.event
		const isDocument = value?._response?.status===201
		const videodurationInSeconds = videoDuration || value?._response?.video_duration__c || ''
		const newState = [...questionData];
		let index = newState.findIndex(item => item.id === name);
		let question = newState.find(record => record.id === name)

		question = onChangeQuestionAnswer(question, value, isDocument, false, videodurationInSeconds)

		newState[index] = question
		setQuestionData(newState)
		// validateInput(newState)

		if(isDocument){
			var temp = errors.filter(obj => obj.id != name)
            setErrors(temp)
			handleSubmit(questionData, heroku_id, status, true, name)
			// delete question.document
			// newState[index] = question
			// setQuestionData(newState)
		}
		// let valid = applicantValidation(questionData, [], applicationDependentConfigs);
		// setErrors(valid !== 'true' ? valid : [])
	}

	/**
	 * Handle task form submission.
	 */
	const submitTask = (e) => {
		e.preventDefault();
		let valid = validateInput(questionData, questions);
		if (valid) {
			handleSubmit(questionData, heroku_id, status, false)
		}
	}

	const handleShowError = (id) => {
		var message = ""
		if (errors.length > 0) {
		  errors.map((item) => {
			if (id === item.id) {
			  message = item.message
			}
		  })
		  return message
		}
		return null
	  }
	/**
	 * Check input validatons
	 */
	const validateInput = (data, questions) => {
		const answerType = ['Output Message', 'Document']
		let errors = []
		if (Array.isArray(questions)) {
			questions.forEach(record => {
				if (record.documents && record.documents.length < record.minimum_doc_upload_required__c) {
					errors.push({
						id: record.id,
						message: `Please upload a minimum of ${record.minimum_doc_upload_required__c} ${record.minimum_doc_upload_required__c < 2 ? 'Document' : 'Documents'}.`
					})
				} else if (record.answer_type__c == 'Document' && isEmpty(record.document || record.documents) && (record.is_required__c || record.doc_upload_required__c)) {
					errors.push({
						id: record.id,
						message: `Please upload a document.`
					})
				}else if (record.answer_type__c !== 'Document' && (record.doc_upload_required__c && isEmpty(record.document || record.documents))) {
					errors.push({
						id: record.id,
						message: `Please upload a document.`
					})
				}else 
				if (!record.question_answer__c && !answerType.includes(record.answer_type__c) && record.is_required__c) {
					errors.push({
						id: record.id,
						message: 'Please enter/select your answer'
					})
				}
			});
		}

		// data.map(record => {
		// 	if (!record.question_answer__c && isEmpty(record.document) && record.answer_type__c !== 'Output Message' && (record.is_required__c || record.doc_upload_required__c) || (!record.question_answer__c && record.answer_type__c === "Dropdown" && record.is_required__c)) {
		// 		errors[record.id] = 'Please enter/select your answer.'
		// 	}
		// 	return null
		// })
		setErrors(errors);
		return isEmpty(errors)
	}
	const handleDocumentVisibility = (record = {}) => {
	    let isVisible = (record.doc_upload_required__c || getQuestionDataType(record.answer_type__c) === "Document")
	    return isVisible && ((record.maximum_doc_upload_required__c ? (record.maximum_doc_upload_required__c <= (record.documents ? record.documents.length : 0)) : false))
  	}

	/**
	 * Render Application Question Input
	 * @returns {JSX.Element}
	 */
	const renderApplicationInput = () => questions.map((question) => {
		return (
			<div className="application-section__question" key={question.heroku_id}>
				<label className="application-section__label">
					{parseHtml(question.question_label__c)}
				</label>
				<Field
					id={question.id}
					className="application-section__textarea"
					minNumber={question.exit_config__c ? '' : question.min_value__c}
					maxNumber={question.exit_config__c ? '' : question.max_value__c}
					minDateFilter={question.exit_config__c ? '' : question.min_date__c}
					maxDateFilter={question.exit_config__c ? '' : question.max_date__c}
					description={parseHtml(question.question_description__c)}
					// errorMsg={(question.answer_type__c === "Dropdown" && question.is_required__c && !getAnswer(question.id)) ? true : errors[question.id]}
					// errorMsg={errors[question.id]}
					errorMsg={handleShowError(question.id)}

					placeholder='Please answer.'
					options={formatAnswerOptions(question.answer_options__c || '', question.answer_type__c)}
					type={getQuestionDataType(question.answer_type__c)}
					value={getQuestionAnswer(question)}
					required={question.required__c || question.minimum_word_required__c && question.word_count_enable__c}
					// uploadedDocument={getDocumentObject(question)}
					has_document={question.doc_upload_required__c}
					handleChange={(value, videoDuration) => onChange(question.id, value, videoDuration)}
					documentHide={handleDocumentVisibility(question)}
					onChangeRemoveSet={true}
					// videoLable = {findLabel(question)}

					minWordCountLongText={question.minimum_word_required__c ? question.minimum_word_required__c : false}
					// charLimit={question.word_count_enable__c && question.answer_type__c === 'Long Text' ? question.word_count__c : question.word_count__c || 2500}
					charLimit={question.answer_type__c === 'Long Text' ?  question.word_count_enable__c ? question.word_count__c ? question.word_count__c : 25000 : 25000  : 255}

					wordCount={question.word_count_enable__c ? question.word_count__c : false}
					handlePasteText={(e) => handlePasteText(e, question.id, question.word_count_enable__c ? question.word_count__c : false)}
				/>
				{question.documents && question.documents.length > 0 &&
		            question.documents.map((doc) =>
					<Field
		                id={doc.sfid || doc.heroku_id__c}
		                className="application-section__textarea"
		                labelClassName="application-field-label-style"
		                type={'Document'}
		                value={doc}
		                uploadedDocument={doc}
		                handleDocumentDelete={(doc) => deleteUploadedDocument(question.id, doc)}
		              />
		            )
		        }
			</div>
		)
	})

	/**
	* Fetch answer from state
	* @returns string
	*/
	const getAnswer = (id) => {
		const question = questions.find(question => question.id === id)
		return question ? question.question_answer__c : ''
	}

	/**
	 * Delete Recommendation Question Document
	 * @returns {JSX.Element}
	 */
	const deleteUploadedDocument = async (questionId, doc) => {
		const newState = Object.assign(questionData, []);
		let question = newState.find(record => record.id === questionId)
			const response = await API.deleteUploadedDocument({ token: token, recommendationid: heroku_id, id: question.heroku_id__c, documentid: doc })
			response.success ? toast.success(<FlashMessage message={response.message} />) : toast.error(<FlashMessage type="error" message={response.error}  />)
			await deleteRecommendationQuestionDoc();

	}

	return (
		<div id='task-modal-content'>
			<div className="task-head_container recommender_header_cl">
				{userContext === "DONOR"  && <div className="H1DesktopGreen event-head padding_left_header">{title}</div>}
				<a className="event-head H6DesktopGrey" onClick={handleClose}>X</a>
			</div>



			{ questions.length > 0 ? <div className="user-task-add__form">
				<form onSubmit={(e) => submitTask(e)}> 


					{renderApplicationInput()}


					<div className="applicant-task__submit">
						<CTAButton id='task-page__cta__cancel' type='inverse' outline onClick={handleClose}>
							Cancel
						</CTAButton>

						<CTAButton id='task-page__cta' type='tertiary'>
							Submit
						</CTAButton>
					</div>

				</form>
			</div>  : <div className='DesktopGreen expiration-alert-client'>No questions assigned</div>}
		</div>
	)
}