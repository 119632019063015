import React, { useState, useEffect } from 'react';
import { dataTypeDoc } from '../../../../config/main';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import CTAButton from '../../CTAButton/CTAButton';
// import './Donors.scss';
import Field from '../../Field/Field';
import SweetAlert from 'react-bootstrap-sweetalert';
import './ApplicationUpdate.scss';
import FlashMessage from '../../FlashMessage/Message';
import { ToastContainer, toast } from 'react-toastify';
import ApplicationSteps from './AppicationSteps';
import { TableListingLoader } from '../../Loader/Loader';

export default ({ accountData, setModalState }) => {

    const API = new KaleidoscopeAPI({});

    const [name, setApplicationName] = useState('');
    const [application, setApplication] = useState('');
    const [scholarship, setScholarship] = useState('');
    const [loading, setLoading] = useState(false);
    const [ isActiveID, setIsActiveID ] = useState(false)
    const [isOpenSweetAlert, setIsOpenSweetAlert] = useState(false)

    const searchUser = async (e) => {
        e.preventDefault();
        setLoading(true)
        await apiCall()
    }

    const apiCall = async (apname) => {
        setApplication('')
        setScholarship('')
        const result = await API.applicationDoc({ token: accountData.token, applicationName: apname || name });
        if (result.application) {
            setApplication(result.application)
            setScholarship(result.scholarship)
        } else {
            toast.error(<FlashMessage type="error" message={result.message} />)
        }
        setLoading(false)
    }

    const resetDetail = (e) => {
        e.preventDefault();
        setApplication('')
        setScholarship('')
        setApplicationName('')
    }

    const alretMsg = (data, documentId) => {
        const getAlert = (data, documentId) => (
            <>
                <div className="save-changes-confirmation-alert">
                    <SweetAlert
                        warning
                        title="Are you sure?"
                        showConfirm={true}

                        confirmBtnText="Okay"
                        onConfirm={() => {setLoading(true);removeDocument(data, documentId)}}
                        showCancel={true}

                        cancelBtnText="Cancel"
                        onCancel={(e) => setIsOpenSweetAlert(false)}

                        cancelBtnCssClass="cta-button CTAWhite primary cta-button__text"
                        confirmBtnCssClass="cta-button CTAWhite tertiary cta-button__text"
                        className=""
                    >
                    </SweetAlert>
                </div>
            </>
        );
        setIsOpenSweetAlert(getAlert(data, documentId))
    }


    const deleteApplicationDocument = async (data, documentId)  => {
        alretMsg(data, documentId)
    }
    /**
     * Delete Application Question Document
     * @returns {JSX.Element}
     */
    const removeDocument = async (data, documentId)  => {
        setLoading(true);
        setIsOpenSweetAlert(false)
        const response = await API.deleteApplicationDocument({
                                    'token': accountData.token,
                                    scholarshipId: scholarship.sfid,
                                    applicationStepId: data.application_step__c,
                                    applicationId: application.sfid,
                                    documentId: documentId
                                })
        setIsActiveID(data.application_step__c)
        if (!response.success) {
            toast.error(<FlashMessage message={`Something went wrong.`} />);
            setLoading(false);
        } else {
            await apiCall(application.name)
            toast.success(<FlashMessage message={`Removed successfully.`} />);
        }
    }

    /**
     * Add Application Question Document
     * @returns {JSX.Element}
     */
     const addApplicationDocument = async (data, value, type, videoDuration = '')  => {
        setLoading(true);
        const { url } = value?._response?.request;
		const {file_Name} = value?._response || '';
        const videodurationInSeconds = videoDuration || value?._response?.video_duration__c || ''
    
        let answer = data.answer || '' 
        let document = {
          doc_url__c: url.split("?").shift(),
          doc_name__c: file_Name,
          ...(videodurationInSeconds ? { video_duration__c: videodurationInSeconds } : {}),
        };
        let applicationData = {
            sfid: data.sfid,
            answer: answer,
            document: document,
            question_set_number: data.question_set_number
        }
        if(type !== 'iteration'){
            delete applicationData.question_set_number
        }
        const response = await API.AddApplicationDocument({
                                    'token': accountData.token,
                                    applicationId: application.sfid,
                                    applicationData: applicationData
                                })
        setIsActiveID(data.application_step__c)
        if (!response.success) {
            toast.error(<FlashMessage message={`Something went wrong.`} />);
            setLoading(false);
        } else {
            await apiCall(application.name)
            toast.success(<FlashMessage message={`Saved successfully.`} />);
        }
    }


    return (
        <div className="admin-main">
            {/*  */}
            <div className="update-recommender-menu">
                <div className="update-recommender">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <div className="H5DesktopGreen">Find Application</div>
                            <hr className="H5HeadingDivider" />
                            <form onSubmit={(e) => searchUser(e)}>
                                <Field
                                    id='admin-login-email-id'
                                    label='Application ID'
                                    placeholder='Enter Application ID. Example: AP1234'
                                    type='text'
                                    toolTipMessage='Get the application documents details by using application ID.  Example: AP1234'
                                    handleChange={value => setApplicationName(value)}
                                    value={name}
                                    required
                                />
                                <div className="footer-btn">
                                    <CTAButton id='search' type='primary'>
                                        Search
                                    </CTAButton>
                                    <CTAButton id='reset' onClick={(e) => resetDetail(e)}>
                                        Reset
                                    </CTAButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {isOpenSweetAlert}
                {loading && <div className="skeleton-loader__container"><TableListingLoader /></div> }
                {scholarship &&
                    <div className="promote-user-menu">
                        <div className="promote-user">

                            <div className="H5DesktopGreen">Update Application Documents: <span class="counts">{application.name}</span></div>
                            <hr className="H5HeadingDivider" />

                            <div className="application-review-section ">
                               { application.steps_data ? <ApplicationSteps
                                    accountData= {accountData}
                                    application={application}
                                    scholarship={scholarship}
                                    account={''}
                                    setModalState= {setModalState}
                                    token={accountData.token}
                                    deleteApplicationDocument={deleteApplicationDocument}
                                    addApplicationDocument={addApplicationDocument}
                                    isActiveID={isActiveID}
                                /> :  <div className='DesktopGreen expiration-alert-admin'>No document steps available</div>}


                            </div>
                        </div>
                    </div>
                }
            </div >
        </div >
    )
}