import { uuid4 } from '@sentry/utils';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import AdminSSoDetailsModal from '../AdminSSoDetailsModal/AdminSSoDetailsModal';
import Field from '../Field/Field';
import FlashMessage from "../FlashMessage/Message"
import Loader from '../Loader/Loader';
import "./AdminSSoDetails.scss"

const AdminSSoDetails = ({ accountData, setModalState }) => {


  const API = new KaleidoscopeAPI({});

  const [accountId, setAccountId] = useState("")
  const [ssoAccountsData, setSSOAccountsData] = useState([]);
  const [ssoAccountVal, setSSOAccountVal] = useState({ label: "Select SSO Account", value: "" })

  let getSSOAccountList = async () => {
    setLoading(true)
    const result = await API.ssoDetailsList({ token: accountData.token });
    if (result) {
      setSSOAccountsData(result.accounts)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSSOAccountList()
  }, [])


  const data = {
    sso_detail: {
      idp_entity_id: "",
      idp_sso_target_url: "",
      idp_slo_target_url: "",
      is_active: true,
      idp_certificate: "",
      sp_entity_id: "",
      sp_init_url: "",
      sp_consume_url: "",

      primary_identifier: [{ key: "", value: "", id: uuid4() }],
      secondary_identifier: [{ key: "", value: "", id: uuid4() }],
      field_mappings: [],
      redirect_url:"",
      idp_client_id:"",
      idp_client_secret:"",
      sso_type:"",
      idp_api_scopes:"",

    }
  }

  function isEmptyObject(obj) {
    return JSON.stringify(obj) === '{}'
  }

  const objToArr = (obj) => {
    let clonedObj = obj.sso_detail;
    let newObj = {
      "sso_detail": {
        ...clonedObj
      }
    }
    let field_mappings = []
    let primary_identifier = []
    let secondary_identifier = []

    if (isEmptyObject(newObj.sso_detail.field_mapping)) {
      field_mappings.push({ key: "", value: "", id: uuid4() })
    } else {
      Object.entries(obj.sso_detail.field_mappings).map((entry) => {
        field_mappings.push({ key: entry[0], value: entry[1], id: uuid4() })
      })
    }

    if (isEmptyObject(newObj.sso_detail.primary_identifier)) {
      primary_identifier.push({ key: "", value: "", id: uuid4() })
    } else {
      let priArr = Object.entries(newObj.sso_detail.primary_identifier)[0]
      primary_identifier.push({ key: priArr[0], value: priArr[1], id: uuid4() })
    }

    if (isEmptyObject(newObj.sso_detail.secondary_identifier)) {
      secondary_identifier.push({ key: "", value: "", id: uuid4() })
    } else {
      let secArr = Object.entries(newObj.sso_detail.secondary_identifier)[0]
      secondary_identifier.push({ key: secArr[0], value: secArr[1], id: uuid4() })
    }

    newObj.sso_detail.primary_identifier = primary_identifier;
    newObj.sso_detail.secondary_identifier = secondary_identifier;
    newObj.sso_detail.field_mappings = field_mappings;
    return newObj
  }

  let arr = ["primary_identifier", "secondary_identifier", "field_mappings"]


  const [newFeildMappings, setNewFeildMappings] = useState(data)
  const [showForm, setShowForm] = useState(false)
  const [loading, setLoading] = useState(false)

  const checkDuplicateKeys = (newFeildMappings) => {
    let temp = []

    arr.map((item) => {
      newFeildMappings.sso_detail[item].map((mapping) => {
        temp.push(mapping.key);
      })
    })

    var isDuplicate = temp.some(function (item, idx) {
      if (item !== "") {
        return temp.indexOf(item) != idx
      }
    });
    return isDuplicate
  }

  const handleChange = (type, value, obj, data, id = "") => {
    if (obj === "sso_detail") {
      let clonedObj = newFeildMappings[obj];
      setNewFeildMappings({
        [obj]: {
          ...clonedObj,
          [type]: value
        }
      })
    }
    else {
      newFeildMappings.sso_detail[obj].forEach(entry => {
        if (obj === "field_mappings") {
          if (entry.id === id) {
            entry[data] = value
          }
        } else {
          entry[data] = value;
        }
      })
    }
  }

  const searchSSoDetails = async (val) => {
    setLoading(true);
    // setNewFeildMappings(data)
    await apiCall(val)
  }

  const apiCall = async (val) => {
    const result = await API.SSODetailsAdmin({ token: accountData.token, accountId: val.value });
    if (result.sso_detail) {
      if (result.sso_detail.created_at !== null) {
        setNewFeildMappings(objToArr(result))
        setShowForm(true)
      } else {
        setNewFeildMappings(objToArr(result))
        setShowForm(true)
      }
    } else {
      {
        toast.error(<FlashMessage type="success" message={result.message} />)
      }
    }
    setLoading(false)
  }

  const arrToObj = (arr) => {
    let main = []
    arr.map((item) => {
      let temp = []
      temp.push(item.key)
      temp.push(item.value)
      main.push(temp)
    })

    let newObj = main.reduce((acc, [k, v]) => (acc[k] = v, acc), {})
    return newObj
  }

  const prepareData = (data) => {
    let clonedObj = data.sso_detail;

    let primary_identifier = arrToObj(clonedObj.primary_identifier);
    let secondary_identifier = arrToObj(clonedObj.secondary_identifier);
    let field_mapping = arrToObj(clonedObj.field_mappings);

    let newObj = {
      "sso_detail": {
        ...clonedObj,
        "primary_identifier": primary_identifier,
        "secondary_identifier": secondary_identifier,
        "field_mappings": field_mapping
      }
    }

    delete newObj.sso_detail.account_sfid
    delete newObj.sso_detail.id
    delete newObj.sso_detail.created_at
    delete newObj.sso_detail.created_by
    delete newObj.sso_detail.updated_at
    delete newObj.sso_detail.updated_by
    delete newObj.sso_detail.redirect_url
    delete newObj.sso_detail.sp_consume_url
    delete newObj.sso_detail.sp_entity_id
    delete newObj.sso_detail.sp_init_url



    arr.map((obj) => {
      if (Object.keys(newObj.sso_detail[obj])[0] === "") {
        newObj.sso_detail[obj] = {}
      }
    })
    return newObj
  }

  const handleSubmit = async (e, accountId) => {
    e.preventDefault()
    if (!checkDuplicateKeys(newFeildMappings)) {
      setLoading(true)
      const result = await API.ssoDataSend({ token: accountData.token, accountId: accountId, data: prepareData(newFeildMappings) });
      if (result.success) {
        setShowForm(false)
        setNewFeildMappings(data)
        setSSOAccountVal({ label: "Select SSO Account", value: "" })
        toast.success(<FlashMessage type="success" message={result.message} />)

        const listResult = await API.ssoDetailsList({ token: accountData.token });
        if (listResult) {
          setSSOAccountsData(listResult.accounts)
          setLoading(false)
        }
      } else {
        setLoading(false)
        toast.error(<FlashMessage type="error" message={result.message} />)
      }

    } else {
      toast.error(<FlashMessage type="error" message={'Duplicate Key(s) are not allowed.'} />)
    }
  }


  return (
    <div className="admin-main">
      <Loader loading={loading} />
      <div className="update-recommender-menu">
        <div className="update-recommender">
          <div className="section group">
            <div className="col span_12_of_12 sso-admin-dropdown" >
              <div className="main-heading-div">
                <div className="H5DesktopGreen">Select SSO Account</div>
              </div>
              {/* <hr className="H5HeadingDivider" /> */}
              <Field
                className='field-group__field-full'
                type='select'
                // label='Select SSO Account'
                value={ssoAccountVal}
                options={ssoAccountsData}
                placeholder='Select SSO Account'
                handleChange={(val) => {
                  setNewFeildMappings(data);
                  if (val === '') {
                    setShowForm(false);
                  } else {
                    setSSOAccountVal(val);
                    searchSSoDetails(val);
                    setAccountId(val.value)
                  }
                }}
                required={true}
                createPicklist={true}
                toolTipMessage = {"From here you can create new SSO details by entering the account's SFID or Heroku ID."}
              />

            </div>
          </div>

        </div>
        <div className="promote-user-menu">
          <div className="admin-sso-details-form">
            {showForm && <AdminSSoDetailsModal handleChange={handleChange} handleSubmit={handleSubmit} accountId={accountId} newFeildMappings={newFeildMappings} setNewFeildMappings={setNewFeildMappings} />
            }          </div>
        </div>
      </div >
    </div >
  )
}

export default AdminSSoDetails