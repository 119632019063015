import React from "react"
import "./RecordPerPage.scss"
import Field from "../../Components/Field/Field"
import { useDispatch } from 'react-redux';
import { reduxPage } from "../../Core/store";
import { currentItemPerPage } from "../../hooks/currenttabIndexAction";


const RecordPerPage = ({ itemCountOnPage, setItemCountOnPage = () => { }, setCurrentPage = () => { }, isScholarshipPage = false, page }) => {

    let itemCountFromLocal = localStorage.getItem('recordPerPage') ? JSON.parse(localStorage.getItem('recordPerPage')) : {}

    const dispatch = useDispatch();


    const handleChange = (e) => {
        dispatch(currentItemPerPage(e))
        setItemCountOnPage(e)
        itemCountFromLocal[page] = e
        if (page) {
            localStorage.setItem('recordPerPage', JSON.stringify(itemCountFromLocal))
        }
        setCurrentPage(1)
    }

    const recordPerPageOptions = [
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
    ]

    if (isScholarshipPage !== true) {
        recordPerPageOptions.push({ label: "1000", value: 1000 })
    }

    return (
        <>
            <div className='record-per-page-field-container'>
                <Field
                    className='record-per-page-field-container__field'
                    type='select'
                    label='Record per page'
                    options={recordPerPageOptions}
                    value={itemCountFromLocal[page] ? itemCountFromLocal[page] : { label: "10", value: 10 }}
                    handleChange={handleChange}
                />
            </div>
        </>

    )
}

export default reduxPage(RecordPerPage);