import React from 'react';
import "./HerokuErrorDetailsModal.scss"


export default ({
  handleClose = () => { },
  token,
  id,
  data,
  setLoading = () => { }

}) => {

  React.useEffect(() => {
    setLoading(false)
  }, [])

  const showJson = (object) => {
    let result = []

    for (const property in object) {
      result.push(<>
        <li>"{`${property}`}" : "{`${object[property]}`}"</li>
      </>)
    }
    return result
  }

  return (

    <div id='task-modal-content' className='iteration-modal-box'>
      <div className="task-head_container">
        <div className="H2DesktopGreen event-head">Record</div>
        <a className="event-head H6DesktopBlack" onClick={handleClose}>X</a>
      </div>
      <div className="">
        <ul className="text-primary location-container">
          {
            showJson(data)
          }
        </ul>

      </div>
    </div >
  )
}