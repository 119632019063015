import React from 'react';
import { Stream } from "@cloudflare/stream-react";

const VideoPlayer = ({ embedCode }) => {

  return (
    <div>
      <Stream controls src={embedCode} />
    </div>
  );
};

export default VideoPlayer;