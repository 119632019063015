import React, { useState, useEffect } from 'react';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import CTAButton from '../../CTAButton/CTAButton';
import Field from '../../Field/Field';
import FlashMessage from '../../FlashMessage/Message';
import Loader from '../../Loader/Loader';
import { toast } from 'react-toastify';

export default ({ accountData }) => {
    const API = new KaleidoscopeAPI({});
    const [programId, setProgramId] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCoplyleaks = (e) => {
        e.preventDefault();
        setLoading(true)
        if (programId) {
            handleCoplyleaksScan()
        }
    }

    const handleCoplyleaksScan = async () => {
        const response = await API.copyLeaksScan({ token: accountData.token, scholarship_Id: programId });
        if (response.success) {
            toast.success(<FlashMessage type="success" message={response.message} />)
        } else {
            toast.error(<FlashMessage type="error" message={response.message} />)
        }
        setLoading(false)
        setProgramId('')
    }

    return (
        <div className="admin-main">
            <Loader loading={loading} />
            <div className="user-activities-menu">
                <div className="user-activities">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <form onSubmit={(e) => handleCoplyleaks(e)}>
                                <div className="H5DesktopGreen">Copyleaks Scan</div>
                                <hr className="H5HeadingDivider" />
                                <Field id='user-activity' label='Scholarship ID' placeholder='Enter scholarship ID' type='text'
                                    toolTipMessage="From here you can run a Copyleaks scan batch by entering the scholarship's SFID or Heroku ID. Note: Enable_Copyleaks_Scan__c must be true at the account level and scholarship level."
                                    handleChange={value => setProgramId(value)}
                                    value={programId}
                                    required
                                />
                                <div className="footer-btn">
                                    <CTAButton id='get-activities' type='primary'>
                                        Start Copyleaks Scan
                                    </CTAButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* */}
        </div>
    )
}