import React, { useState, useEffect } from 'react';
import { isArray } from 'lodash';
import SpaceInvader from '../../SVG/SpaceInvader'
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import CTAButton from '../../CTAButton/CTAButton';
// import './Donors.scss';
import Field from '../../Field/Field';
import { TableListingLoader } from '../../Loader/Loader';
import AirPlaneWithShadow from '../../SVG/AirPlaneWithShadow';
import SweetAlert from 'react-bootstrap-sweetalert';
import Paginator from '../../Paginator/Paginator';
import Globe from '../../SVG/Globe';
import History from '../../SVG/History';
import './UserActivites.scss';
import Userinfo from '../../SVG/Userinfo';
import Calendar from '../../SVG/Calendar';
import Eyes from '../../SVG/Eyes';
import Emailhistory from '../../SVG/Emailhistory';
import User from '../../SVG/User';
import FormatDate from '../../../Utility/FormatDate';
import { MAIN_CONFIG } from '../../../../config/main';
import FlashMessage from '../../FlashMessage/Message';
import { ToastContainer, toast } from 'react-toastify';


export default ({ accountData, setModalState }) => {

  const API = new KaleidoscopeAPI({});

  const [email, setEmail] = useState('');
  const [userActivitys, setUserActivity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTotalRecords, setCurrentTotalRecords] = useState(0);

  /**
   * Fetches the data for the specified page number.
   * @param {number} page
   */
  const handlePagination = async (page) => {
    setCurrentPage(page)
    setLoading(true)
    await apiCall(page)
  }
  const resetDetail = (e) => {
    e.preventDefault();
    setUserActivity([])
    setEmail('')
    setCurrentPage(0)
    setCurrentTotalRecords(0)
  }

  const searchUser = async (e) => {
    e.preventDefault();
    setLoading(true)
    await apiCall(1)
  }

  const apiCall = async (page) => {
    const result = await API.userActivity({ token: accountData.token, userEmail: email, page: page });
    if (result.user) {
      setUserActivity(result.activity_logs)
      setCurrentTotalRecords(result.pagination.totalRecords)
    } else {
      toast.error(<FlashMessage type="error" message={result.message} />)
    }
    setLoading(false)
  }

  const getRecord = (data) => {
    const createMarkup = (string) => {
      return { __html: string };
    }

    if (isArray(data)) {
      return data.map((record, index) => {
        return (
          <tr>
            <td dangerouslySetInnerHTML={createMarkup(record.label__c || record.question)}></td>
            <td>{record.answer}</td>
          </tr>
        )
      })

    }

  }

  // const activityData = (activity) => {
  //   if (activity.params) {
  //     let data = JSON.parse(activity.params)
  //     const keys = Object.keys(data)
  //     const values = Object.values(data)
  //     return keys.map((key, index) => {
  //       if (typeof (data[key]) === 'object') {
  //         return getRecord(data[key])
  //       } else {
  //         return (
  //           <tr>
  //             <td>{key}</td>
  //             <td>{values[index]}</td>
  //           </tr>
  //         )
  //       }
  //     })
  //   }

  const activityLocation = (paramsData = '{}') => {
    let data = JSON.parse(paramsData);
    return data ? JSON.stringify(data, undefined, 4) : "No records found."
  }

  const activityData = (activity = '{}') => {
    let data = JSON.parse(activity);
    return data ? JSON.stringify(data, undefined, 4) : "No records found."
  }


  return (
    <div className="admin-main">
      {/*  */}
      <div className="user-activities-menu">
        <div className="user-activities">
          <div className="section group">
            <div className="col span_12_of_12">
              <form onSubmit={(e) => searchUser(e)}>
                <div className="H5DesktopGreen">User Activity</div>
                <hr className="H5HeadingDivider" />
                <Field
                  id='user-activity'
                  label='Email Address'
                  placeholder='Enter Email Address'
                  type='text'
                  toolTipMessage='Get the user activity'
                  handleChange={value => setEmail(value)}
                  value={email}
                  required
                />
                <div className="footer-btn">
                  <CTAButton id='get-activities' type='primary'>
                    Get Activities
                  </CTAButton>
                  <CTAButton id='reset' onClick={(e) => resetDetail(e)}>
                    Reset
                  </CTAButton>
                </div>
              </form>
            </div>
          </div>
        </div>
        {(loading) ? <div className="skeleton-loader__container"><TableListingLoader /></div> : userActivitys.length > 0 &&
          <div className="email-history-menu">
            <div className="email-history">
              <div className="section group">
                <div className="col span_12_of_12">
                  <div className="H5DesktopGreen">Application Activities</div>
                  <hr className="H5HeadingDivider" />

                  <div className="timeline">
                    {userActivitys.map(activity => {
                      return (
                        <>
                          <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                            <div className="timeline__event__icon ">
                              <div className="timeline__event__date">
                                <div className="date"><Userinfo width={50} height={50} /></div>
                              </div>
                            </div>
                            <div className="timeline__event__content ">
                              <div>
                                <div className="timeline__event__title">
                                  <Emailhistory width={24} height={24} /> <span className="email-subject">{activity.note}</span>
                                </div>
                                <div className="timeline__event__description">
                                  <Calendar width={30} height={30} /> Date : {activity.created_at}
                                </div>
                              </div>
                            </div>
                            <div className="timeline__event__expand">
                              <Eyes />
                            </div>
                          </div>

                          <div className="timeline__event view-email-details active-box">
                            <div className="view-email-details__output">
                              <div className="p status-due font-weight-black text-secondary text-md">


                                {/*  */}
                                <div className="key-and-value-container">
                                  <div className="key-and-value-container__key">
                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Browser:
                                  </div>
                                  <div className="key-and-value-container__value">
                                    {activity.browser}
                                  </div>
                                </div>
                                {/*  */}

                                {/*  */}
                                <div className="key-and-value-container">
                                  <div className="key-and-value-container__key">
                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Ip Address:
                                  </div>
                                  <div className="key-and-value-container__value">
                                    <pre className="text-primary">{activity.ip_address}</pre>
                                  </div>
                                </div>
                                {/*  */}

                                {/*  */}
                                <div className="key-and-value-container">
                                  <div className="key-and-value-container__key">
                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Controller:
                                  </div>
                                  <div className="key-and-value-container__value">
                                    <pre className="text-primary">{activity.controller}</pre>
                                  </div>
                                </div>
                                {/*  */}

                                {/*  */}
                                <div className="key-and-value-container">
                                  <div className="key-and-value-container__key">
                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Action:
                                  </div>
                                  <div className="key-and-value-container__value">
                                    <pre className="text-primary">{activity.action}</pre>
                                  </div>
                                </div>
                                {/*  */}

                                {/*  */}
                                <div className="key-and-value-container">
                                  <div className="key-and-value-container__key">
                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> User Location:
                                  </div>
                                  <div className="key-and-value-container__value">
                                    <pre className="text-primary location-container">{activityLocation(activity.location)}</pre>
                                  </div>
                                </div>
                                {/*  */}

                                {/*  */}
                                {/*<div className="key-and-value-container">
                                  <div className="key-and-value-container__key">
                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Field Values:
                                  </div>
                                  <div className="key-and-value-container__value">
                                    <table className="table table-striped">
                                      <thead>
                                        <tr>
                                          <th scope="col">Field</th>
                                          <th scope="col">Value</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {activityData(activity)}
                                      </tbody>
                                    </table>
                                  </div>
                                </div> */}
                                {/*  */}

                                <div className="key-and-value-container">
                                  <div className="key-and-value-container__key">
                                    <Globe width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlack} /> Field Values:
                                  </div>
                                  <div className="key-and-value-container__value">
                                    <pre className="text-primary location-container">{activityData(activity.params)}</pre>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })
                    }
                  </div>
                </div>
              </div>
            </div>
            <Paginator
              currentPage={currentPage}
              totalRecords={currentTotalRecords}
              perPage={perPage}
              handlePageClick={handlePagination}
            />
          </div>
        }
      </div >
    </div >
  )
}