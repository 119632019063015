import React from 'react';
import './paginator.scss';
import ArrowDown from '../SVG/ArrowDown';
import { MAIN_CONFIG } from '../../../config/main';
import Field from '../../Components/Field/Field';


export default ({ currentPage = 1, totalRecords = 0, perPage = 10, handlePageClick = () => { }, showRecordPerPage = false  , setItemCountOnPage , itemCountOnPage , setCurrentPage , tableDataLength , totalRecordsCount, pageType = ''}) => {
    const totalPages = Math.floor(totalRecords / perPage) + (totalRecords % perPage !== 0 ? 1 : 0);
    const hasPrevPage = currentPage > 1;
    const hasNextPage = currentPage < totalPages;
    const nextPage = currentPage + 1;
    const prevPage = currentPage - 1;
    const hasPrevPageOffset = currentPage - 1 > 1;
    const hasNextPageOffset = currentPage + 1 < totalPages;
    const prevPageOffset = currentPage - 1;
    const nextPageOffset = currentPage + 1;
    const showFirstPage = currentPage >= 2 && currentPage !== 1;
    const showLastPage = currentPage !== totalPages;
    const showEndingEllipses = currentPage < totalPages - 2;
    const showBeginningEllipses = currentPage > 3 && currentPage !== 1;

    const recordPerPageOptions = [
        { label: '10', value: 10 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
        { label: '500', value: 500 },
        { label: '1000', value: 1000 },
        { label: '5000', value: 5000 },
    ]

    const showPagination = ()=>{
        if(showRecordPerPage){
            return totalRecordsCount > tableDataLength
        }else{
            return true
        }
    }

    return (
     <div className='paginator__container' id={`${totalPages > 1 ? pageType : ''}`}>
           { showPagination() && <div className={`paginator H6DesktopGreen ${totalPages <= 1 ? 'hide' : ''}`}>
                {
                    hasPrevPage &&
                    <button className='paginator__prev' onClick={() => handlePageClick(prevPage)}>
                        <ArrowDown color={MAIN_CONFIG.COLORS.fillBlack} ariaLabel="Previous Page" ariaLabelDescription="" />
                    </button>
                }
                {
                    showFirstPage &&
                    <button className='paginator__page' onClick={() => handlePageClick(1)} aria-label={`Page 1`}>
                        1
                    </button>
                }
                {
                    showBeginningEllipses &&
                    <span className='paginator__page'>
                        ...
                    </span>
                }
                {
                    hasPrevPageOffset &&
                    <button
                        className='paginator__page'
                        aria-label={`Page ${prevPageOffset}`}
                        onClick={() => handlePageClick(prevPageOffset)}>
                        {prevPageOffset}
                    </button>
                }

                <button className='paginator__page active' aria-label={`Page ${currentPage} active`}>
                    {currentPage}
                </button>

                {
                    hasNextPageOffset &&
                    <button
                        className='paginator__page'
                        aria-label={`Page ${nextPageOffset}`}
                        onClick={() => handlePageClick(nextPageOffset)}>
                        {nextPageOffset}
                    </button>
                }
                {
                    showEndingEllipses &&
                    <span className='paginator__page'>
                        ...
                    </span>
                }
                {
                    showLastPage &&
                    <button className='paginator__page' onClick={() => handlePageClick(totalPages)} aria-label={`Page ${totalPages}`}>
                        {totalPages}
                    </button>}
                {
                    hasNextPage &&
                    <button className='paginator__next' onClick={() => handlePageClick(nextPage)}>
                        <ArrowDown color={MAIN_CONFIG.COLORS.fillBlack} ariaLabel="Next Page" ariaLabelDescription="" />
                    </button>
                }
            </div> }
            {/* {
                showRecordPerPage &&
                <div className='record-per-page-container'>
                    <Field
                        className='record-per-page-container__dropdown-field'
                        type='select'
                        label='Record per page'
                        options={recordPerPageOptions}
                        value={itemCountOnPage}
                        handleChange = {handleChange}
                    />
                </div>
            } */}
        </div>

    );
};