import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "100", height = "100", secondaryColor = MAIN_CONFIG.COLORS.fillRed }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 100 100">
            <title>Refresh</title>
            <desc>Refresh</desc>
            <defs>
                <clipPath id="clip-icon-refresh">
                    <rect width="100" height="100" />
                </clipPath>
            </defs>
            <g id="icon-refresh" clipPath="url(#clip-icon-refresh)">
                <g id="Icon_feather-refresh-cw" data-name="Icon feather-refresh-cw" transform="translate(-0.167 0.491)">
                    <path id="Path_7785" data-name="Path 7785" d="M95.833,16.667v25h-25" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="8.333" />
                    <path id="Path_7786" data-name="Path 7786" d="M4.167,83.333v-25h25" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="8.333" />
                    <path id="Path_7787" data-name="Path 7787" d="M14.625,37.5A37.5,37.5,0,0,1,76.5,23.5L95.833,41.667M4.167,58.333,23.5,76.5a37.5,37.5,0,0,0,61.875-14" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="8.333" />
                </g>
            </g>
        </svg>


    );
};
