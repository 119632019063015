import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, width="16px", height="16px", ariaLabel="Success", className, color = MAIN_CONFIG.COLORS.fillWhite, iconColor = MAIN_CONFIG.COLORS.fillBlack }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id={id} className={className} width={width} height={height} viewBox="0 0 16 16">
            <title>{ariaLabel}</title>
            <desc>Success</desc>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g transform="translate(-294 -2421) translate(278 2265) translate(0 96) translate(16 60)">
                                <circle cx="8" cy="8" r="8" fill={color} />
                                <g fill={iconColor}>
                                    <path d="M6.193 6.29L4.155 4.217 3.118 5.237 6.087 8.256 12.824 2.745 11.903 1.619z" transform="translate(0 3.5)" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
