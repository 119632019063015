import React from 'react';
import "../FilterSelectorItem/filter-selector-item.scss"
import Field from '../Field/Field';
import Divider from '../Divider/Divider';
import "./AdminRecordFilterModal.scss"
import { isObject } from 'lodash';


export default ({
  handleClose = () => { },
  setLoading = () => { },
  optionData,
  ApiCallHerokuConnectError = () => { },
  tableNames = [],
  setCurrentPage = ()=> {},
  currentPage = {},
}) => {


  let localFiltersData = JSON.parse(localStorage.getItem("adminTriggerlogFilters"));

  let filtersObj = {
    days: 0,
    sf_message: {
      operator: "",
      message: ""
    },
    table_name: ""
  }

  const [activeFilters, setActiveFilters] = React.useState(localFiltersData ? localFiltersData : filtersObj)


  // React.useEffect(() => {
  //   handleChange(selectedOptions, "days")
  // }, [selectedOptions])

  const handleChange = (value, filterName, subName) => {
    if (filterName === "sf_message") {
      setActiveFilters({
        ...activeFilters, [filterName]: {
          ...activeFilters.sf_message, [subName]: subName === "message" ? value : {label : value.label , value : value.value}
        }
      })
    } else {
      setActiveFilters({
        ...activeFilters, [filterName]: isObject(value) ? value.value : value
      })
    }
  }

  const handleClear = (e) => {
    e.preventDefault()
    
    localStorage.removeItem('adminTriggerlogFilters')

    let obj = {
      days: 0,
      sf_message: {
        operator: "",
        message: ""
      },
      table_name: ""
    }
    ApiCallHerokuConnectError(obj, 0, true)
  }

  const handleApply = (e) => {
    localStorage.setItem("adminTriggerlogFilters", JSON.stringify(activeFilters))
    ApiCallHerokuConnectError(activeFilters, 0, true)
  }

  return (

    <div id='task-modal-content' className='iteration-modal-box heroku-error-modal'>
      <div className="task-head_container">
        <div className="H2DesktopGreen event-head">Filters</div>
        <a className="event-head H6DesktopBlack" onClick={handleClose}>X</a>
      </div>
      <div className="">

        <div className="filter-selector-item__modal-section-heading">
          <span className="filter-selector-item__modal-section-title H6DesktopGreen">
            {"Select Days"}
          </span>
        </div>

        <div className="filter-selector-item__dropdown-option-wrapper">
          <Divider className="filter-selector-item__modal-section-divider" />
          {/* <div className="filter-selector-item__dropdown-title CTA color__dark-grey">Select Days</div> */}
          <input
            type="range"
            min={1}
            max={30}
            step={1}
            value={parseInt(activeFilters.days)}
            onChange={(event)=> handleChange(event.target.value , "days")}

            className="filter-selector-item__dropdown-range-selector"
          />
          <span className="filter-selector-item__dropdown-range-amount BodyDefaultBoldBlack">
            {activeFilters.days ? parseInt(activeFilters.days) : 0}
          </span>
        </div>

        <div className="filter-selector-item__modal-section-heading">
          <span className="filter-selector-item__modal-section-title H6DesktopGreen">
            SF Message
          </span>
        </div>

        <Divider className="filter-selector-item__modal-section-divider" />

        <div className="error-message-filter">
          <Field
            label='Select Operator'
            placeholder='Select Operator'
            type='select'
            options={optionData}
            value={{ label: activeFilters.sf_message.operator.label, value: activeFilters.sf_message.operator.value }}
            handleChange={(value) => handleChange(value, "sf_message", "operator")}
          />

          <Field
            label='SF Message'
            placeholder='Add Message'
            type='text'
            value={activeFilters.sf_message.message}
            handleChange={(value) => handleChange(value, "sf_message", "message")}
          />
        </div>

        <div className="filter-selector-item__modal-section-heading">
          <span className="filter-selector-item__modal-section-title H6DesktopGreen">
            {"Select Table"}
          </span>
        </div>

        <Divider className="filter-selector-item__modal-section-divider" />

        <Field
          // label='Select Table'
          placeholder='Select Table'
          type='select'
          options={tableNames}
          value={{ label: activeFilters.table_name, value: activeFilters.table_name }}
          handleChange={(value) => handleChange(value, "table_name")}
        />

      </div>

      <div className="btn-div">
        <button className="filter-selector-item__save-btn CTA clear-btn" aria-label="Save button" onClick={(e) => handleClear(e)}>Clear</button>

        <button className="filter-selector-item__save-btn CTA apply-filters-button" aria-label="Save button" onClick={(e) => {
          setCurrentPage({...currentPage,herokuConnectErrors:1});
          handleApply(e);
        }}>Apply Filters</button>
      </div>
    </div >
  )
}