import Book from '../../SVG/Book'
import Paper from '../../SVG/Paper'
import ApplicationReviewText from '../ApplicationReviewText/ApplicationReviewText'
import { useRouter } from 'next/router';

import './application-review-iteration.scss'

/**
 * Returns Application Review Attachments component
 *
 * @param {object} props
 * @param {string} props.type
 * @param {string} props.name
 * @param {string} props.description
 *
 * @returns {ReactElement}
 */

export default function ApplicationReviewAttachments(props) {
  const {
    items,
    title,
    deleteApplicationDocument = () => {},
    setModalState = () => {}
  } = props
  const router = useRouter();
  const handleView = (e) => {
      e.preventDefault();
      if(url) {
        window.open(url, '_blank')
      }
  }

  const formatQuestionsData = () => {
    return items.map((field, index) => {
      return {
        type: 'text',
        ordinal: index + 1,
        heading: field.label__c,
        content: field,
        dataType: field.data_type__c,
        documents: field.document ? field.document : [],
      }
    })
  }

  const renderItemsMarkup = () => {
    let srno = 0
    return formatQuestionsData().map((item, i) => {
      if(item.dataType !== 'Output Message'){
        srno = srno + 1
      }
      return(
        <ApplicationReviewText
          ordinal={ srno }
          heading={ item.heading }
          content={ item.content }
          documents={item.documents}
          desciptionQuestion= {item.content.description__c}
          dataType={ item.dataType }
          wordCount={ item.wordCount }
          isFirst={ i === 0 }
          key={ item.content.sfid }
          question={item}
          deleteApplicationDocument = {deleteApplicationDocument}
          setModalState={setModalState}
        />
      )
    })
  }

  return (
    <>
      <div className="application-iteration-section__top">
        <h3 className="application-iteration-section__heading">
          {title}
        </h3>
      </div>
      {renderItemsMarkup()}
    </>
  )
}

/**
 * Returns Attachment type icon
 *
 * @param {'recommendation' | 'file'} type
 *
 * @returns {ReactElement}
 */

function renderIcon(type) {
  let icon = null

  switch (type) {
    case 'recommendation':
      icon = <Book className="application-review-iteration__icon" />

      break
    case 'file':
      icon = <Paper className="application-review-iteration__icon" />

      break
  }

  return icon
}
