import React, {useEffect} from 'react';
import AppPage from '../../Core/AppPage';
import dynamic from 'next/dynamic'
import './recommender-home-page.scss';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import { alretMsg } from '../../Utility/ApplicationFormUtility'

const Globe = dynamic(
    () => import('../../Components/SVG/Globe'),
    { loading: () => <p>...</p> }
)

const TabbedTablePage = dynamic(
    () => import('../../Components/TabbedTablePage/TabbedTablePage'),
    { loading: () => <p>...</p> }
)

const CTAButton = dynamic(
    () => import('../../Components/CTAButton/CTAButton'),
    { loading: () => <p>...</p> }
)

import { MAIN_CONFIG } from '../../../config/main';
import { useRouter } from 'next/router';

const { URLS } = MAIN_CONFIG;
export const ReviewerHome = ({
    pageData,
    accountData,
    userContext,
    setActiveHeaderNav,
    setShowTableActionBar,
    setTableActionBarNumSelected,
    setTableActionBarNumTotal
}) => {
    setActiveHeaderNav('dashboard');
    const router = useRouter();
    const [tableDataStatusCounts, setTableDataStatusCounts] = React.useState({})
    const [isOpenSweetAlert, setIsOpenSweetAlert] = React.useState(false)
    const { applicant_recommendations, recommendation_count_based_on_status, persona_branding, pagination } = pageData;
    const API = new KaleidoscopeAPI({});
    const showUnsubscribeConfirmationPopup = localStorage.getItem('show_unsubscribe_confirmation') || false
    // Gather Application Recommendation table data
    // TODO: The recommender will not have this data provided from the back-end, so need to update with proper values
    useEffect(() => {
        if (showUnsubscribeConfirmationPopup === 'true') {
            alretMsg("", setIsOpenSweetAlert, () => { }, false, '', () => {
                localStorage.setItem('show_unsubscribe_confirmation', false);
            },
                'Email Unsubscribe Confirmation',
                true,
                false
            );
            const timeoutId = setTimeout(() => {
                setIsOpenSweetAlert(false);
                localStorage.setItem('show_unsubscribe_confirmation', false);
            }, 30000);
            return () => clearTimeout(timeoutId);
        }
    }, []);
    const allTabData = [{
        "subtab_name__c": "To Complete",
        "status_for_api_call__c": "Draft",
        "order__c": 1,
        "table_headers": [
            {
                "field_api_name__c": "first_name__c",
                "column_name__c": "First Name",
                "enable_sort__c": true,
                "enable_search__c": true,
                "order__c": 1,
                "enable_filter__c": true,
                "field_data_type__c": "Text",
                "operator__c": "contains;equals"
            },
            {
                "field_api_name__c": "last_name__c",
                "column_name__c": "Last Name",
                "enable_sort__c": true,
                "enable_search__c": true,
                "order__c": 2,
                "enable_filter__c": true,
                "field_data_type__c": "Text",
                "operator__c": "contains;equals"
            },
            {
                "field_api_name__c": "scholarship_name__c",
                "column_name__c": "Program",
                "enable_sort__c": true,
                "enable_search__c": true,
                "order__c": 3,
                "enable_filter__c": false,
                "field_data_type__c": "Date",
                "operator__c": null
            },
            {
                "field_api_name__c": "account.name",
                "column_name__c": "Sponsor",
                "enable_sort__c": false,
                "enable_search__c": false,
                "order__c": 4,
                "enable_filter__c": false,
                "field_data_type__c": "Text",
                "operator__c": null,
                "tdCustomClass": "sponsor-tag",
                // "primaryColor": "account.primary_color__c"
            },
            {
                "field_api_name__c": "name",
                "column_name__c": "Code",
                "enable_sort__c": true,
                "enable_search__c": true,
                "order__c": 5,
                "enable_filter__c": false,
                "field_data_type__c": "Text",
                "operator__c": null,
                "isCodeOnRideSide": "code-deadline-right-side"
            },
            {
                "field_api_name__c": "due_date_time__c",
                "column_name__c": "Application Deadline Date",
                "enable_sort__c": true,
                "enable_search__c": false,
                "order__c": 6,
                "enable_filter__c": false,
                "field_data_type__c": "Date",
                "operator__c": null,
                "isCodeOnRideSide": "code-deadline-right-side"
            }
        ]
    }, {
        "subtab_name__c": "Completed",
        "status_for_api_call__c": "Submitted",
        "order__c": 2,
        "table_headers": [
            {
                "field_api_name__c": "first_name__c",
                "column_name__c": "First Name",
                "enable_sort__c": true,
                "enable_search__c": true,
                "order__c": 1,
                "enable_filter__c": true,
                "field_data_type__c": "Text",
                "operator__c": "contains;equals"
            },
            {
                "field_api_name__c": "last_name__c",
                "column_name__c": "Last Name",
                "enable_sort__c": true,
                "enable_search__c": true,
                "order__c": 2,
                "enable_filter__c": true,
                "field_data_type__c": "Text",
                "operator__c": "contains;equals"
            },
            {
                "field_api_name__c": "scholarship_name__c",
                "column_name__c": "Program",
                "enable_sort__c": true,
                "enable_search__c": true,
                "order__c": 3,
                "enable_filter__c": false,
                "field_data_type__c": "Date",
                "operator__c": null
            },
            {
                "field_api_name__c": "account.name",
                "column_name__c": "Sponsor",
                "enable_sort__c": false,
                "enable_search__c": false,
                "order__c": 4,
                "enable_filter__c": false,
                "field_data_type__c": "Text",
                "operator__c": null,
                "tdCustomClass": "sponsor-tag",
                // "primaryColor": "account.primary_color__c"
            },
            {
                "field_api_name__c": "name",
                "column_name__c": "Code",
                "enable_sort__c": true,
                "enable_search__c": true,
                "order__c": 5,
                "enable_filter__c": false,
                "field_data_type__c": "Text",
                "operator__c": null,
                "isCodeOnRideSide": "code-deadline-right-side"
            },
            {
                "field_api_name__c": "due_date_time__c",
                "column_name__c": "Application Deadline Date",
                "enable_sort__c": true,
                "enable_search__c": false,
                "order__c": 6,
                "enable_filter__c": false,
                "field_data_type__c": "Date",
                "operator__c": null,
                "isCodeOnRideSide": "code-deadline-right-side"
            }
        ]
    }];

    const rawTableData = applicant_recommendations;
    const rawTableDataProperty = 'applicant_recommendations';
    const pageTitle = '';
    const searchPlaceholder = 'Search';
    const ctaText = '';
    const ctaIcon = Globe;
    const ctaAction = () => { };
    const tableDataFetch = async (options) => {
        const { token } = accountData;
        var result = await API.fetchApplicantRecommendations({ token, options })
        setTableDataStatusCounts(result.recommendation_count_based_on_status)
        return result
    }
    const tableDataPostFetch = (tableData) => {
        // TODO: Determine why the property check is necessary to prevent error.
        return tableData[rawTableDataProperty] ? tableData[rawTableDataProperty] : [];
    }

    /**
     * Gets the CTA to show for a specific applicant.
     * @param {object} applicant
     * @returns {JSX.Element}
     */
    const getRowCta = (applicant) => {
        // TODO: Add applicant link once account is available for Recommenders
        return (
            <CTAButton type='secondary' small onClick={() => clickTableDataView(applicant)} >
                View
            </CTAButton>
        );
    }

    const clickTableDataView = (applicant) => {
        router.push(`/recommender/recommendation/${applicant.heroku_id__c ? applicant.heroku_id__c : applicant.sfid}`, undefined, { shallow: true })
    }

    /**
     * Returns the Action in row.
     * @param
     * @returns {object[]}
     */
    const getRowMenu = (applicant) => {
        let rowData = [
            { link: '#', onClick: (e) => tableDataPostFetch(), label: 'Question' },
            { link: '#', onClick: (e) => tableDataPostFetch(), label: 'Summary' }
        ];
        return rowData
    }


    return (
        <>
            <TabbedTablePage
                rawTableData={rawTableData}
                tableDataFetch={tableDataFetch}
                tableDataPostFetch={tableDataPostFetch}
                allTabData={allTabData}
                pageTitle={pageTitle}
                pagination={pagination}
                clickTableDataView={clickTableDataView}
                tableDataStatusCounts={tableDataStatusCounts}
                searchPlaceholder={searchPlaceholder}
                ctaIcon={ctaIcon}
                ctaText={ctaText}
                ctaAction={ctaAction}
                accountData={accountData}
                setShowTableActionBar={setShowTableActionBar}
                setTableActionBarNumSelected={setTableActionBarNumSelected}
                setTableActionBarNumTotal={setTableActionBarNumTotal}
                userContext={userContext}
                id='recommender-home-page'
                getRowCta={getRowCta}
                // getRowMenu={getRowMenu}
                showSelect={false}
                showRowCta={true}
                headerBackgroundColor={MAIN_CONFIG.COLORS.fillBeigePagesModalsPopUps}
            />
            {isOpenSweetAlert}
        </>
    );

}

const ReviewerHomePage = AppPage(ReviewerHome);

export default ReviewerHomePage;
