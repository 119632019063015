import React, { useState, useEffect } from 'react';
import SpaceInvader from '../../SVG/SpaceInvader'
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import Table from '../../Table/Table';
import Paginator from '../../Paginator/Paginator';
import { debounce } from 'throttle-debounce';
import { TableListingLoader } from '../../Loader/Loader';
import Score from '../../SVG/Score';
import { MAIN_CONFIG } from '../../../../config/main';
import Field from '../../Field/Field';
import CTAButton from '../../CTAButton/CTAButton';
import LoginModal from '../../LoginModal/LoginModal';
import { checkMarketPlace } from '../../../Utility/HelperFunctions';
import Download from '../../SVG/Download';


export default ({ accountData, setModalState }) => {
    const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
    const API = new KaleidoscopeAPI({});
    const [search, setSearch] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [currentTableData, setCurrentTableData] = useState([]);

    const [currentSortDirection, setCurrentSortDirection] = useState('');
    const [currentSortColumn, setCurrentSortColumn] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [currentTabTotalRecords, setCurrentTabTotalRecords] = useState(0); // Tracks current tab


    const columnData = {
        table_headers: [
            { field_api_name__c: 'user_name', column_name__c: 'Name', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'user_email', column_name__c: 'Email', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'note', column_name__c: 'Note', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'browser', column_name__c: 'Browser Info', enable_sort__c: true, enable_search__c: true },
            { field_api_name__c: 'created_at', column_name__c: 'Date', enable_sort__c: true, enable_search__c: true },
        ]
    };

    useEffect(() => {
        updateTableData();
    }, [currentPage, currentSortColumn, currentSortDirection]);

    /**
     * Returns the columns that should be passed when performing a search.
     * @returns {*}
     */
    const getCurrentSearchableColumns = () => {
        return columnData.table_headers.filter(item => item.enable_search__c).map(item => item.field_api_name__c);
    }

    /**
     * Fetch new scholarships based on sort request.
     * @param {string} columnName
     * @param {string} currentSortDirection
     */
    const handleSort = async (columnName, currentSortDirection) => {

        const newSortDirection = currentSortDirection.toUpperCase() === 'ASC' ? 'DESC' : 'ASC';
        setCurrentSortColumn(columnName);
        setCurrentSortDirection(newSortDirection);
    }


    /**
     * Fetches the data for the specified page number.
     * @param {number} page
     */
    const handlePagination = page => {
        setCurrentPage(page)
        setIsLoading(true)
    }

    /**
     * Update the table data respecting sort, search, and pagination.
     * @returns {Promise<void>}
     */
    const updateTableData = async () => {
        const options = {
            page: currentPage,
            sort: currentSortColumn ? `${currentSortColumn} ${currentSortDirection}` : false
        }

        const updatedTableData = await API.loginHistory({
            token: accountData.token,
            options
        });
        setIsLoading(false)
        // TODO: Determine why the property check is necessary to prevent error.
        const tableData = updatedTableData.activity_logs ? updatedTableData.activity_logs : [];
        const { pagination } = updatedTableData;
        setCurrentTabTotalRecords(pagination.totalRecords);
        setCurrentTableData(tableData);

    }

    const handleExport = () => {
        var clientId = process.env.NEXT_PUBLIC_DROPBOX_APP_KEY
        var localURL = MAIN_CONFIG.URLS.SERVER_URL
        localStorage.setItem('localURL', localURL)
        
    }



    return (
        <div className="admin-main">
            <div className="login-history-menu">
                <div className="login-history">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <div className="H5DesktopGreen">
                                <div>
                                    Login History
                                    ({currentTabTotalRecords})
                                </div>
                                {/* <div>
                                    <CTAButton id='search' type='primary' onClick={() => handleExport()}>
                                        <Download width={25} height={25} color={MAIN_CONFIG.COLORS.fillWhite} className="download-icon" />  Export History
                                    </CTAButton>
                                </div> */}
                            </div>
                            <hr className="H5HeadingDivider" />
                            {(isLoading) ? <div className="skeleton-loader__container"><TableListingLoader /></div> : (
                                <Table
                                    id={'applicant-screen__clients-table'}
                                    showSelect={false}
                                    columnData={columnData}
                                    tableData={currentTableData}
                                    handleSort={handleSort}
                                    currentSortColumn={currentSortColumn}
                                    currentSortDirection={currentSortDirection}
                                />
                            )}
                            <Paginator
                                currentPage={currentPage}
                                totalRecords={currentTabTotalRecords}
                                perPage={perPage}
                                handlePageClick={handlePagination}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}