import ApplicationReviewAttachment from '../ApplicationReviewAttachment/ApplicationReviewAttachment'
import './application-review-text.scss'
import { parseHtml } from '../../../Utility/HelperFunctions';
import { getResumeAnswer, findLabel, getAnswer } from '../../../Utility/ApplicationFormUtility';
import Field from '../../Field/Field'
import cookies from 'next-cookies';
import { getQuestionDataType, getQuestionDocDataType } from '../../../Utility/RecommendationDataTypeMapping';
import { isEmpty } from 'lodash';

/**
 * Returns Application Review Text component
 *
 * @param {object} props
 * @param {number} props.ordinal
 * @param {string} props.heading
 * @param {string} props.content
 * @param {number} props.wordCount
 * @param {boolean} props.isFirst
 *
 * @returns {ReactElement}
 */

export default function ApplicationReviewText(props) {
  const {
    ordinal,
    heading,
    content,
    wordCount,
    desciptionQuestion = "",
    isFirst,
    dataType = '',
    documents = [],
    recommendation = false,
    deleteApplicationDocument = () => { },
    addApplicationDocument = () => { },
    applicationUpdateFunction = false,
    FormType = '',
    setModalState = () => { }
  } = props
  var admincontext = cookies('context').admincontext
  var usercontext = cookies('context').context

  return (
    <div className={`
      application-review-text
      ${isFirst ? 'application-review-text--first' : ''}
    `}
      key={content.sfid}
    >
      <h3 className="application-review-text__heading">
        <span className="application-review-text__ordinal">
          {dataType === 'Output Message' ? '' : `${ordinal}.`}
        </span>
        {parseHtml(heading)}
      </h3>
      <p className="application-review-text__content">
        <span className="view-signature-field">
          {desciptionQuestion && parseHtml(desciptionQuestion)}
        </span>
      </p>

      <p className="application-review-text__content answer-value">
        <span className="view-signature-field BodyDefaultRegularBlack">
          {getResumeAnswer(dataType, content, recommendation)}
        </span>
      </p>

      {/* {wordCount &&
        <div className="application-review-text__word-count">
          Word count: {wordCount}
        </div>
      } */}
      
      {!isEmpty(usercontext) && !applicationUpdateFunction ?

        documents.length > 0 && documents.map((document) => {
          return (
            <ApplicationReviewAttachment
              type='file'
              name={document.doc_name__c}
              urlObj={document}
              key={Math.random()}
              sfid={content.sfid}
              className="documentField"
              setModalState={setModalState}
            />
          )
        })
        : (admincontext === "ADMIN" || applicationUpdateFunction) &&
        <>
          {(FormType === 'iteration' && documents.length === 0) &&
            <Field
              id={content.id}
              className="application-section__textarea"
              placeholder='Please answer.'
              type={getQuestionDocDataType(dataType) || "Document"}
              has_document={content.doc_upload_required__c}
              handleChange={(value, videoDuration) => addApplicationDocument(content, value, FormType, videoDuration)}
              max_video_length_in_seconds__c={content.max_video_length_in_seconds__c}
              min_video_length_in_seconds__c={content.min_video_length_in_seconds__c}
              videoLable={findLabel(content)}
            />
          }
          {FormType !== 'iteration' && !((content.doc_upload_required__c || getQuestionDataType(content.data_type__c) === "Document") && content.maximum_doc_upload_required__c && parseInt(content.maximum_doc_upload_required__c) === (content.document ? content.document.length : 0)) &&
            <Field
              id={content.id}
              className="application-section__textarea"
              placeholder='Please answer.'
              type={getQuestionDocDataType(dataType) || "Document"}
              has_document={content.doc_upload_required__c && !((content.doc_upload_required__c || getQuestionDataType(content.data_type__c) === "Document") && content.maximum_doc_upload_required__c && parseInt(content.maximum_doc_upload_required__c) === (content.document ? content.document.length : 0))}
              documentHide={((content.doc_upload_required__c || getQuestionDataType(content.data_type__c) === "Document") && content.maximum_doc_upload_required__c && parseInt(content.maximum_doc_upload_required__c) === (content.document ? content.document.length : 0))}
              handleChange={(value, videoDuration) => addApplicationDocument(content, value, FormType, videoDuration)}
              videoLable={findLabel(content)}
              handleDocumentDelete={() => deleteApplicationDocument(content, doc && doc.sfid || doc.heroku_id__c)}
              max_video_length_in_seconds__c={content.max_video_length_in_seconds__c}
              min_video_length_in_seconds__c={content.min_video_length_in_seconds__c}
              documentRemoveAlert={true}
            />
          }

          {documents && documents.length > 0 &&
            documents.map(doc =>
              <Field
                id={doc.sfid || doc.heroku_id__c}
                className="application-section__textarea"
                type={getQuestionDocDataType(dataType) || "Document"}
                value={doc}
                // description={"==================="}
                uploadedDocument={doc}
                handleChange={(value, videoDuration) => addApplicationDocument(content, value, FormType, videoDuration)}
                documentRemoveAlert={true}
                handleDocumentDelete={() => deleteApplicationDocument(content, doc && doc.sfid || doc.heroku_id__c)}
                max_video_length_in_seconds__c={content.max_video_length_in_seconds__c}
                min_video_length_in_seconds__c={content.min_video_length_in_seconds__c}
                has_document={content.doc_upload_required__c}
                videoLable={findLabel(content)}
                documentHide={((content.doc_upload_required__c || getQuestionDataType(content.data_type__c) === "Document") && content.maximum_doc_upload_required__c && parseInt(content.maximum_doc_upload_required__c) === (content.document ? content.document.length : 0))}
              />
            )
          }
        </>
      }
    </div>



  )
}
